import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DeepDiffObject } from '../../enum/util/deep-diff-object.enum';
import { SentencecasePipe } from '../../pipes/sentencecase.pipe';
import _ from 'lodash';
import { ObjectNestedValueByPathModel, ObjectNestedValueByPathEnum } from '../../model/structure/util.model';

export class FormatPlaceholderSpecificCase {
	from: string;
	to: string;
}

@Injectable({
	providedIn: 'root'
})
export class AngularCoreUtilService {

	constructor(
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		public sentencecasePipe: SentencecasePipe
	) {}

	replaceAll(valueToTest: string, regExp: RegExp, replacer: string = '') {
		if (!valueToTest) {
			return;
		}
		if (replacer) {
			replacer = '';
		}

		let res: string = valueToTest;

		while (regExp.test(res)) {
			res = res.replace(regExp, replacer);
		}

		return res.trim();
	}

	getEnumValue(index: number, enumType) {
		const enumValueArray = Object.keys(enumType).map(enumKey => enumType[enumKey]);
		if (index > enumValueArray.length - 1) {
			return undefined;
		}
		const val = enumValueArray[index];
		return val;
	}

	getElementIndex(array: any[], property: string, value: string): number {
		for (let i = 0; i < array.length; i++) {
			if (array[i][property] == value) {
				return i;
			}
		}
	}

	/**
	 *
	 * Permette la selezione delle options di una mat-select nel caso in cui venga bindato nel mat-option [value] un oggetto
	 * e non un semplice ID. Le condizioni dell'if dovrebbero diventare parametriche.
	 *
	 * @param options array che genera le options
	 * @param values valori da selezionare derivanti ad esempio da una response
	 */
	compareObjectForMatSelect(options, values) {
		const a = [];
		options.forEach(o => {
			values.forEach(v => {
				if (
					o.organization === v.organization &&
					o.channel === v.channel &&
					o.group_customer === v.group_customer
				) {
					a.push(o);
				}
			});
		});
		return a;
	}

	deepDiffMapper(obj1: any, obj2: any) {
		if (this.isFunction(obj1) || this.isFunction(obj2)) {
			throw new Error('Invalid argument. Function given, object expected.');
		}
		if (this.isValue(obj1) || this.isValue(obj2)) {
			return {
				type: this.compareValues(obj1, obj2),
				data: obj1 === undefined ? obj2 : obj1
			};
		}
		const diff = {};
		for (const key in obj1) {
			if (this.isFunction(obj1[key])) {
				continue;
			}

			let value2: any;
			if ('undefined' != typeof obj2[key]) {
				value2 = obj2[key];
			}

			diff[key] = this.deepDiffMapper(obj1[key], value2);
		}
		for (const key in obj2) {
			if (this.isFunction(obj2[key]) || 'undefined' !== typeof diff[key]) {
				continue;
			}

			diff[key] = this.deepDiffMapper(undefined, obj2[key]);
		}
		return diff;
	}

	compareValues(value1: any, value2: any) {
		if (value1 === value2) {
			return DeepDiffObject.VALUE_UNCHANGED;
		}
		if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
			return DeepDiffObject.VALUE_UNCHANGED;
		}
		if ('undefined' == typeof value1) {
			return DeepDiffObject.VALUE_CREATED;
		}
		if ('undefined' == typeof value2) {
			return DeepDiffObject.VALUE_DELETED;
		}

		return DeepDiffObject.VALUE_UPDATED;
	}
	isFunction(obj: any) {
		return {}.toString.apply(obj) === '[object Function]';
	}
	isArray(obj: any) {
		return {}.toString.apply(obj) === '[object Array]';
	}
	isObject(obj: any) {
		return {}.toString.apply(obj) === '[object Object]';
	}
	isDate(obj: any) {
		return {}.toString.apply(obj) === '[object Date]';
	}
	isValue(obj: any) {
		return !this.isObject(obj) && !this.isArray(obj);
	}

	getGlobalColor(colorName: string): string {
		let colorValue = '';
		const getCSSProp = (element, propName) => getComputedStyle(element).getPropertyValue(propName);
		colorValue = getCSSProp(document.documentElement, colorName);

		if (!colorValue) {
			console.error('Custom properties not valid: ' + colorName);
		}
		return colorValue.trim();
	}

	/**
	 * Restituisce l'oggetto privo delle proprietà settate a null, undefined o array vuoto
	 * @param object any
	 */
	deleteEmptyProperties(object) {
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				if (
					object[key] === null ||
					object[key] === undefined ||
					object[key].length === 0 ||
					(Object.keys(object[key]).length === 0 && object[key].constructor === Object)
				) {
					delete object[key];
				}
				// controllo ricorsivo su proprietà di tipo array
				if (object[key] && Array.isArray(object[key])) {
					for (let i = 0; i < object[key].length; i++) {
						object[key][i] = this.deleteEmptyProperties(object[key][i]);
					}
					// elimina eventuali oggetti vuoti -> {}
					object[key] = object[key].filter(i => !_.isEmpty(i));
				}
			}
		}
		return object;
	}

	/**
	 * Formatta il placeholder del mat-inpu
	 *
	 * 1 sostituisce "and" con "_&_"
	 * 2 sostituisce "_" e "-" con " "
	 * (3 nel template agisce la pipe titlecase)
	 *
	 * @param field la proprietà passata anche al formControlName
	 * @param handleSpecificCase se si vogliono gestire le casistiche presenti in `formatPlaceholderSpecificCaseList`
	 */
	formatPlaceholder(field: string, handleSpecificCase?: FormatPlaceholderSpecificCase[]) {
		if (field) {
			if (handleSpecificCase && handleSpecificCase.length > 0) {
				handleSpecificCase.forEach(specificCase => {
					field = field.replace(specificCase.from, specificCase.to);
				});
			}
			field = field.replace(/\_and_/g, '_&_');
			field = field.replace(/[_-]/g, ' ');
			return field;
		}
	}

	// form

	/**
	     * Restituisce il valore di una proprietà annidata all'ennesimo livello di un oggetto. Nel caso si imbatta in un array
	 * lo restituisce secondo la convenzione del model `ObjectNestedValueByPathModel`, permettendo all'implementazione
	 * specifica di scegliere se continuare la ricorsione in uno o in tutti gli elementi della lista.
	 *
	     * @param anyObject : oggetto contenente la proprietà
	 * @param path: percorso annidato dell'oggetto di cui si cerca il valore
	 *
	 * Es: la stringa 'a.b.c' permette di recuperare il valore di 'c' nei due casi seguenti:
	 *
	 * A)
	 *
	 * {
	 *   a: {
	 *     b: {
	 *       c: 'value'
	 *     }
	 *   }
	 * }
	 *
	 * return {
	 *   type: 'FOUND',
	 *   path: 'a.b.c',
	 *   value: 'value'
	 * }
	 *
	 * B)
	 *
	 * {
	 *   a: [
	 *     {
	 *       b: {
	 *         c: 'value'
	 *       }
	 *     }
	 *   ]
	 * }
	 *
	 * return {
	 *   type: 'IS_ARRAY',
	 *   path: 'b.c',
	 *   value: {
	 *     b: {
	 *       c: 'value'
	 *     }
	 *   }
	 * }
	 *
	 * @param anyObject
	 * @param path
	 * @returns
	 */
	checkObjectNestedValueByPath(anyObject: any, path: string): ObjectNestedValueByPathModel {
		// i livelli annidati di path vengono ordinati in un array
		path = path.replace(/\[(\w+)\]/g, '.$1');
		path = path.replace(/^\./, '');
		const pathSplitList = path.split('.');
		// viene iterato pathSplitList
		for (let i = 0; i < pathSplitList.length; ++i) {
			const pathSplitItem = pathSplitList[i];
			if (Array.isArray(anyObject)) {
				// anyObject è un array:
				// viene salvato in subPathSplitList il path partendo dal livello di annidamento
				// non ancora processato dal for loop
				let subPathSplitList: string;
				for (let h = JSON.parse(JSON.stringify(i)); h < pathSplitList.length; h++) {
					if (h === i) {
						subPathSplitList = pathSplitList[h];
					} else {
						subPathSplitList = subPathSplitList + '.' + pathSplitList[h]
					}
				}
				// viene restituito un oggetto composto da:
				// - type: informa che l'oggetto è di tipo array
				// - path: la parte non ancora processata del path originale
				// - value: l'array nel quale il loop si è imbattuto
				// il metodo non prosegue ricorsivamente in modo da permettere la scelta a chi lo implementa (in testObjectNestedValue
				// ad esempio viene rieseguito su ogni elemento che compone value, in modo da restituire l'eventuale true sull'ennesimo
				// elemento testato)
				return <ObjectNestedValueByPathModel>{
					type: ObjectNestedValueByPathEnum.IS_ARRAY,
					path: subPathSplitList,
					value: anyObject
				}
			} else {
				// anyObject è un oggetto:
				if (pathSplitItem in anyObject) {
					// pathSplitItem è una proprietà presente in anyObject:
					// anyObject viene riassegnato al valore della sua prop. pathSplitItem, in modo da continuare la ricorsione
					// cercando i successivi livelli di path nelle proprietà ulteriormente annidate
					anyObject = anyObject[pathSplitItem];
				} else {
					// pathSplitItem NON è una proprietà presente in anyObject:
					// viene restituito un oggetto indicante che la proprietà non è stata trovata
					return <ObjectNestedValueByPathModel>{
						type: ObjectNestedValueByPathEnum.NOT_FOUND
					};
				}
			}
		}
		// al termine del for loop è terminato:
		// viene restituito l'oggetto annidato al livello indicato da path
		return <ObjectNestedValueByPathModel>{
			type: ObjectNestedValueByPathEnum.FOUND,
			value: anyObject
		};
	}

	/**
	 * Verifica il match di un dato valore annidato in 'anyObject' attraverso il metodo 'test()'
	 * sfruttando l'azione ricorsiva di 'checkObjectNestedValueByPath()'.
	 *
	 * In caso venga verificato un array, è sufficiente che un solo elemento superi il test per scatenare 'return true'.
	 *
	 * `filterForLanguageList()` limita la ricorsione ai soli elementi di 'language_list' avente `language`
	 * coincidente a 'languageKey'.
	 *
	 * @param anyObject: oggetto da verificare
	 * @param path: percorso annidato della proprietà da testare
	 * @param regex: regex da testare
	 * @param languageKey: lingua da considerare per l'applicazione di `filterForLanguageList()`
	 * @returns boolean
	 */
	testObjectNestedValue(anyObject: any, path: string, regex: RegExp, languageKey: string): boolean {
		const value: ObjectNestedValueByPathModel =
			this.checkObjectNestedValueByPath(anyObject, path);
		switch (value.type) {
			case ObjectNestedValueByPathEnum.IS_ARRAY:
				// 'checkObjectNestedValueByPath()' restituisce un array:
				this.filterForLanguageList(languageKey, path, value);
				// viene ripetuto 'testObjectNestedValue()' sugli elementi dell'array fino al primo 'return true'
				for (let i = 0; i < value.value.length; i++) {
					if (this.testObjectNestedValue(value.value[i], value.path, regex, languageKey)) {
						return true;
					}
				}
				break;
			case ObjectNestedValueByPathEnum.FOUND:
				// 'checkObjectNestedValueByPath()' restituisce il valore annidato coincidente con quanto indicato in path:
				return regex.test(value.value);
			case ObjectNestedValueByPathEnum.NOT_FOUND:
				return false;
		}
	}

	/**
	 * Restituisce il valore annidato in 'anyObject' attraverso il metodo sfruttando l'azione ricorsiva di
	 * 'checkObjectNestedValueByPath()'.
	 *
	 * In caso venga verificato un array, viene restituito il primo elemento trovato da `returnObjectNestedValue()`.
	 *
	 * `filterForLanguageList()` limita la ricorsione ai soli elementi di 'language_list' avente `language`
	 * coincidente a 'languageKey'.
	 *
	 * @param anyObject
	 * @param path: percorso per giungere al valore
	 * @param languageKey: lingua da considerare per l'applicazione di `filterForLanguageList()`
	 * @returns any || null
	 */
	returnObjectNestedValue(anyObject: any, path: string, languageKey: string) {
		const value: ObjectNestedValueByPathModel = this.checkObjectNestedValueByPath(anyObject, path);
		switch (value.type) {
			case ObjectNestedValueByPathEnum.IS_ARRAY:
				this.filterForLanguageList(languageKey, path, value);
				for (let i = 0; i < value.value.length; i++) {
					const listItemValue = this.returnObjectNestedValue(value.value[i], value.path, languageKey);
					if (listItemValue) {
						return listItemValue;
					}
				}
				break;
			case ObjectNestedValueByPathEnum.FOUND:
				return value.value;
			case ObjectNestedValueByPathEnum.NOT_FOUND:
				return null;
		}
	}

	/**
	 * Filtra gli elementi di un oggetto, in modo da escludere quelli avente `language` diverso da `languageKey`.
	 * Le condizioni sono fortemente legate alla modellazione standard multilingua che vede elementi aventi prop.
	 * `language` a definire la chiave di appartenenza, all'interno di un array `language_list`.
	 * @param languageKey
	 * @param path
	 * @param anyObject
	 */
	filterForLanguageList(languageKey: string, path: string, objectNestedValue: ObjectNestedValueByPathModel) {
		const regexLanguageList = new RegExp('language_list', 'i');
		if (languageKey && regexLanguageList.test(path) && objectNestedValue.value[0].language) {
			objectNestedValue.value = objectNestedValue.value.filter(i => i.language === languageKey);
		}
	}


	getTrendPercentage(oldValue: number, newValue: number): number {
		if (oldValue === 0) {
			newValue++;
			oldValue++;
		}
		return ((newValue - oldValue) / Math.abs(oldValue)) * 100;
	}

	/**
	 * Util method to show dialog when needed
	 * @param msg Message to show
	 * @param action Action to perform
	 * @param duration Dialog duration
	 */
	showDialog(msg: string, action: string = null, duration: number = 3000) {
		msg = this.sentencecasePipe.transform(this.translate.instant(msg));
		this.snackBar.open(msg, action, { duration: duration });
	}

	guid() {
		function S4() {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		}
		return (
			S4() +
			S4() +
			'-' +
			S4() +
			'-4' +
			S4().substr(0, 3) +
			'-' +
			S4() +
			'-' +
			S4() +
			S4() +
			S4()
		).toLowerCase();
	}

	loadSnackbarWithAsyncTranslatedAction(
		message: string,
		action: string,
		time?: number
	) {
		this.translate.get(message).subscribe((messageReturn) => {
			this.translate.get(action).subscribe((actionReturn) => {
				this.snackBar.open(
					messageReturn,
					actionReturn.toUpperCase(),
					{
						duration: time ||
							2000,
					}
				);
			});
		});
	}

}
