import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaCenterModel } from '../../../../model/media-center.model';
import { PermissionEnum } from '../../../../model/enum/permission.enum';
import { MediaCenterService } from '../../../../service/rest/media-center.service';
import { UtilService } from '../../../../service/util.service';
import { DialogDeleteComponent } from '../../../../widget/dialog/dialog-delete/dialog-delete.component';
import { DialogMoveItemListComponent } from '../../../../widget/dialog/dialog-move-item-list/dialog-move-item-list.component';
import { DialogNameEditComponent } from '../../../../widget/dialog/dialog-name-edit/dialog-name-edit.component';
import { UploadService } from '../../../../service/upload/upload.service';
import { finalize } from 'rxjs';
import { AttachmentResolverService } from '@saep-ict/angular-core';

@Component({
	selector: 'media-center-home-sidebar',
	templateUrl: './media-center-home-sidebar.component.html',
	styleUrls: ['./media-center-home-sidebar.component.scss'],
})
export class MediaCenterHomeSidebarComponent implements OnInit {
	@Input() currentFolder: MediaCenterModel;
	/**
	 * L'emitter scatta in caso di avvenuto
	 * - creazione cartella
	 * - upload lista elementi
	 * - spostamento lista elementi
	 *
	 * @memberof MediaCenterHomeSidebarComponent
	 */
	@Output() sidebarEventSuccess = new EventEmitter();
	permissionEnum = PermissionEnum;
	constructor(
		private dialog: MatDialog,
		public utilService: UtilService,
		private mediaCenterService: MediaCenterService,
		public uploadService: UploadService,
		private attachmentResolverService: AttachmentResolverService,
	) { }

	ngOnInit() { }

	// widget & utility
	// dialog
	openDialogCreateFolder(): void {
		const dialog = this.dialog.open(DialogNameEditComponent, {
			width: '80vw',
      panelClass: ['michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((name: string) => {
			if (name) {
				this.createFolder(name);
			}
		});
	}

	openDialogMoveItemList(): void {
		const dialog = this.dialog.open(DialogMoveItemListComponent, {
			data: this.utilService.itemListSelected,
			width: '80vw',
      panelClass: ['michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((res) => {
			if (res) {
				this.sidebarEventSuccess.emit();
			}
		});
	}

	openDialogDeleteItemList(): void {
		const pathList: string[] = this.utilService.itemListSelected.map(i => i.path);
		const dialog = this.dialog.open(DialogDeleteComponent, {
			data: pathList,
			width: '80vw',
      panelClass: ['michelangelo-theme-dialog']
		});
		dialog.afterClosed().subscribe((result) => {
			if (result) {
				this.deleteItemList(pathList);
			}
		});
	}

	// rest
	fileUpload(files: FileList) {
		Array.from(files).forEach((file: File) => {
			this.uploadService.addFileToUpload(file, this.currentFolder.path);
		});
		if (files?.length) {
			try {
				this.uploadService
					.startUpload()
					.pipe(finalize(() => {}))
					.subscribe(v => {
						this.attachmentResolverService.empty();
						this.sidebarEventSuccess.emit();
					});
			} catch(err) {
				this.utilService.loadSnackbarWithAsyncTranslatedAction(
					'mc.error.upload_item',
					'mc.general.close',
					2000
				);
				throw new Error(err);
			}
		}
	}

	createFolder(name: string) {
		this.mediaCenterService
			.createFolder({path: this.utilService.context.value.filter.path, newName: name}, null, {fileType: 'folder'})
			.then((res) => {this.sidebarEventSuccess.emit();})
			.catch((err) => {console.log(err);});
	}

	deleteItemList(pathList: string[]) {
		this.mediaCenterService
			.deleteFileList({pathList: pathList})
			.then((res) => {
				this.sidebarEventSuccess.emit();
			})
			.catch((err) => {
				console.error(err);
			});
	}
}
