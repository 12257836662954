<sidebar-content-wrapper *ngIf="order" [title]="'theme.order.order_aside_help.order_summary'">
	<ng-container main-content>
		<div class="information" *ngIf="order.header.status === orderStatusEnum.DRAFT">
			<h3>{{ 'order.order_aside_help.information' | translate | sentencecase }}</h3>
			<div *ngFor="let field of orderHeaderFieldList" class="item">
				{{ field.label | translate | sentencecase }}
				<span>{{ field.value }}</span>
			</div>
			<a
				*ngIf="order.header.status === orderStatusEnum.DRAFT"
				(click)="router.navigate(['header-edit'], { relativeTo: route })"
				href="javascript:void(0)"
			>
				{{ 'order.order_aside_help.edit_informations' | translate | sentencecase }}
			</a>
		</div>
		<ng-container *ngFor="let csl of categorySectionList">
			<ng-container *ngIf="csl.articleCategoryTree && csl.articleCategoryTree.length > 0">
				<div class="articles">
					<h3>{{ csl.title | translate | sentencecase }}</h3>
					<article-list-category-tree-recursion-summary
						[configuration]="{
							articleCheckoutTree: csl.articleCategoryTree,
							currency: order.header.currency.description_short
						}"
					>
					</article-list-category-tree-recursion-summary>
				</div>
					<div class="total" *ngIf="csl.title === 'theme.article.field.products'">
						{{ 'order.order_aside_help.product_total' | translate | sentencecase }}
						<span>
							{{ totalPriceProduct | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
							{{ order.header.currency.description_short }}
						</span>
					</div>
				<div class="total" *ngIf="csl.title === 'theme.order.category_section_list_description.posm'">	
					{{ 'order.order_aside_help.posm_total' | translate | sentencecase }}
					<span>
						{{ totalPricePosm | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
						{{ order.header.currency.description_short }}
						({{ totPercentPosm  | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}%)
					</span>
				</div>
			</ng-container>
		</ng-container>

		<div class="total-order">
			{{'order.tot_article' | translate | sentencecase }} ({{ totalOrder }})
			<span>
				{{ orderPriceMap.article.value | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
				{{ order.header.currency.description_short }}
			</span>
		</div>
		<div class="total-order" 
			*ngIf=" user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT">
			{{'order.field.price.vat' | translate | sentencecase}}
			<span>
				{{ orderPriceMap.vat.value | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
				{{ order.header.currency.description_short }}
			</span>
		</div>
		<div class="total-order">
			{{ 'order.order_aside_help.total' | translate | sentencecase }}
			<span>
				{{ orderPriceMap.total.value | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
				{{ order.header.currency.description_short }}
			</span>
		</div>
		<form-button-bar
			class="actions-buttons"
			*ngIf="order.header.status === orderStatusEnum.DRAFT"
			[align]="'space-between'"
			[primaryButtonLabel]="
				(routedMainContentKey === ROUTE_URL.checkout ? 'order.send' : 'general.go_to_checkout')
					| translate
					| sentencecase
			"
			[primaryButtonIsDisabled]="
				utilOrderService.orderDisabledViewConfirm ||
				!(
					(order.product_list && order.product_list.length > 0) ||
					(order['free_line_list'] && order['free_line_list'].length > 0)
				) ||
				(
					routedMainContentKey === ROUTE_URL.checkout &&
					offline
				)
			"
			(primaryButtonClickEmitter)="handlePrimaryButton()"
		></form-button-bar>
	</ng-container>
</sidebar-content-wrapper>
