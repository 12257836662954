import { Inject, Injectable } from "@angular/core";
import { appConfigToken } from "./pouchdb.module";
import { AppConfigModel, IPouchAppConfig } from "./app.config";

@Injectable({ providedIn: "root" })
export class TransmissionService {
  forbiddenSelectorList: any[] = [
    {
      type: "order",
      header: {
        status: "DRAFT",
      },
    },
    {
      type: "chat"
    },
    {
      type: "survey"
    }
  ];

  constructor(
    @Inject(appConfigToken)
    private applicationEnvironment: IPouchAppConfig<AppConfigModel>
  ) {}

  async informDbTransmission(requestBody: any, db: PouchDB.Database) {
    const transmission = {
      db_name: db.name.slice(db.name.lastIndexOf("/") + 1, db.name.length),
      code_document: requestBody.code_item,
      type_document: requestBody.type,
    };
    const response = await fetch(
      `${this.applicationEnvironment.envConfig.urlConfig.api}/db_transmission/`,
      {
        method: "PUT",
        body: JSON.stringify(transmission),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const myJson = await response.json();
  }

  isDbTransmissionForbidden(doc: any) {
    for (let selector of this.forbiddenSelectorList) {
      if (this.isForbidden(doc, selector)) {
        return true;
      }
    }
    return false;
  }

  isForbidden(value: any, selector: any): boolean {
    if ([selector, value].includes(null)) {
      return false;
    }
    if (typeof selector === "object" || Array.isArray(selector)) {
      let isForbidden: boolean;
      for (let selectorChild in selector) {
        isForbidden = this.isForbidden(
          value[selectorChild],
          selector[selectorChild]
        );
        if (!isForbidden) {
          return false;
        }
      }
    } else {
      return value === selector;
    }
    return true;
  }
}
