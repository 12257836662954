import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import _ from "lodash";
import { OrderListFilterCreationFieldMapFormValue, OrderListFilterCreationFieldMapSelectOptionMap } from "../../../../../model/order-util.model";
import {
		returnConfigurationCustomerOrderListFilterByFormFieldDefault,
		returnCreationFieldMapWithOptionOrderStatus,
		returnConfigurationCustomerOrderListHeaderFilterByDateRange
} from "../order-list-filter-form-dynamic-util.constant";
import { TableOrderModel } from "../../../../../model/table/table-order.model";

export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
	{
		name: 'date',
		on_form_template: true,
		label: 'order.field.creation_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'submission_date',
		on_form_template: true,
		label: 'theme.order.field.submission_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'first_evasion_date',
		on_form_template: true,
		label: 'theme.order.field.delivery_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'status',
		on_form_template: true,
		label: 'theme.order.order_status',
		type: FormControlMultipurposeEnum.ItemType.SELECT_MULTIPLE
	}
];

export const returnConfigurationCustomerOrderListFilterCreationFieldMapBackoffice = async (
	selectOptionMap: OrderListFilterCreationFieldMapSelectOptionMap,
	orderStatus?: boolean
): Promise<FormControlMultipurposeModel.Item[]> => {
	try {
		let creationFieldMapToReturn = _.cloneDeep(creationFieldMap);
		creationFieldMapToReturn =
			await returnCreationFieldMapWithOptionOrderStatus(
				creationFieldMapToReturn,
				selectOptionMap,
				orderStatus
			);
		return creationFieldMapToReturn;
	} catch(err) {
		throw new Error(err);
	}
}

export const returnConfigurationCustomerOrderListFilterByFormValueBackoffice = async (
    orderList: TableOrderModel[],
    formValue: OrderListFilterCreationFieldMapFormValue
): Promise<TableOrderModel[]> => {
	try {
		if (orderList && orderList.length && formValue) {
			orderList = await returnConfigurationCustomerOrderListFilterByFormFieldDefault(orderList, formValue);
			orderList = await returnConfigurationCustomerOrderListHeaderFilterByDateRange(orderList, formValue, 'first_evasion_date');
			orderList = await returnConfigurationCustomerOrderListHeaderFilterByDateRange(orderList, formValue, 'submission_date');
		}
		return orderList;
	} catch(err) {
		throw new Error(err);
	}
}