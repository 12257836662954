import { FormControlMultipurposeModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import { creationFieldMapContextCodeOrganizationB2c, creationFieldMapContextCodeOrganizationPartialTaxData, creationFieldMapDocumentBase } from '@saep-ict/angular-spin8-core';

export const projectOverride: FormControlMultipurposeModel.Item[] =
  _.cloneDeep(creationFieldMapDocumentBase)
  .concat([
    {
      name: 'date_update',
      on_form_template: false
    },
    {
      name: 'type',
      on_form_template: false
    },
    {
      name: 'source',
      on_form_template: false
    },
    {
      name: 'status',
      on_form_template: false
    },
    {
      name: 'level',
      on_form_template: false
    },
    {
      name: 'organization_type',
      on_form_template: false
    },
    {
      name: 'business_name',
      on_form_template: false
    },
    {
      name: 'destination_list',
      on_form_template: false
    },
    {
      name: 'division_list',
      on_form_template: false
    },
    {
      name: 'contact_list',
      on_form_template: false
    },
    {
      name: 'currency',
      on_form_template: false
    }
  ])
  .concat(_.cloneDeep(creationFieldMapContextCodeOrganizationPartialTaxData))

