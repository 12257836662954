<div
	*ngIf="_configuration"
	class="article-table-wrapper-base"
	class="{{columnWidthConfigurationClass}}"
	[ngClass]="{'sidebarclosed':sidebarClosed}"
>
	<div class="head-wrapper">
		<div
			*ngFor="
				let column of _configuration.localListHandlerData.columnList
					| filterByAllTheBooleans
						: {
								propertyListName: ['hidden'],
								value: false
						  }
			"
		>
			{{ column.labelPath | translate: column.labelPathParam | sentencecase }}
		</div>
	</div>
	<!-- NO virtual scroll: la tabella renderizza gli elementi in base all'eventuale azione
		della paginazione di default -->
	<div
		*ngIf="!(_configuration.virtualScrollConfiguration) ||
			(
				_configuration.virtualScrollConfiguration &&
				!_configuration.virtualScrollConfiguration.enabled
			)
		"
		class="rows-wrapper"
	>
<!--		<div-->
<!--			*ngFor="let row of _configuration.localListHandlerData.dataSubset"-->
<!--			class="content-wrapper"-->
<!--		>-->
<!--			<ng-container *ngTemplateOutlet="rowLoop; context: { row: row }"></ng-container>-->
<!--		</div>-->

		<ng-container *ngFor="let row of _configuration.localListHandlerData.dataSubset; index as i">
			<div class="line-separator" *ngIf="user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT && checkNewLine(i)">
				{{row.articleDescription.aggregated_info.brand}}
			</div>
			<div class="content-wrapper">
				<ng-container *ngTemplateOutlet="rowLoop; context: { row: row }"></ng-container>
			</div>
		</ng-container>


	</div>
	<!-- virtual scroll -->
	<div
		*ngIf="_configuration.virtualScrollConfiguration &&
			_configuration.virtualScrollConfiguration.enabled
		"
		#virtualScrollContainer
		class="rows-wrapper"
	>
		<cdk-virtual-scroll-viewport
			*ngIf="
				_configuration.virtualScrollConfiguration.itemHeight &&
				_configuration.virtualScrollConfiguration.viewportHeight
			"
			[itemSize]="_configuration.virtualScrollConfiguration.itemHeight"
			[style.height.px]="_configuration.virtualScrollConfiguration.viewportHeight"
		>
<!--			<div-->
<!--				*cdkVirtualFor="let row of _configuration.localListHandlerData.dataSubset"-->
<!--				class="content-wrapper"-->
<!--			>-->
<!--				<ng-container *ngTemplateOutlet="rowLoop; context: { row: row }"></ng-container>-->
<!--			</div>-->

			<ng-container *cdkVirtualFor="let row of _configuration.localListHandlerData.dataSubset; index as i">
				<div class="line-separator" *ngIf="user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT && checkNewLine(i)">
					{{row.articleDescription.aggregated_info.brand}}
				</div>
				<div class="content-wrapper">
					<ng-container *ngTemplateOutlet="rowLoop; context: { row: row }"></ng-container>
				</div>
			</ng-container>
		</cdk-virtual-scroll-viewport>
	</div>
</div>
<!-- ng-template -->
<ng-template #rowLoop let-row="row">
	<div
		*ngFor="
			let column of _configuration.localListHandlerData.columnList
				| filterByAllTheBooleans
					: {
							propertyListName: ['hidden'],
							value: false
					}
		"
	>
		<ng-container [ngSwitch]="column.name">
			<!-- Availability -->
			<div *ngSwitchCase="'cf_available'">
				<div class="chips-mini">
					<span
						[ngClass]="articleEnum.Status.Stock[row.articleDescription?.stock?.code_segnalation]"
						[matTooltip]="row.articleDescription?.stock?.description_segnalation"
					></span>
				</div>
			</div>
			<!-- code_item -->
			<div *ngSwitchCase="'code_item'">
				<ng-container
					*ngTemplateOutlet="code_; context: { code: row.code_item | guidFormatter, row: row }"
				></ng-container>
			</div>
			<!-- code_erp -->
			<div *ngSwitchCase="'code_erp'">
				<ng-container
					*ngTemplateOutlet="code_; context: { code: row.code_erp, row: row }"
				></ng-container>
			</div>
			<!-- Description -->
			<div *ngSwitchCase="'description'">
				<ng-template #descriptionValue let-value="value">
					<span [matTooltip]="value">{{ value }}</span>
				</ng-template>
				<ng-container
					*ngTemplateOutlet="
						descriptionValue;
						context: {
							value: utilTranslateService.getTranslationFromLanguage(
								row.articleDescription.language_list
							).description
						}
					"
				>
				</ng-container>
			</div>
			<!-- Product -->
			<div *ngSwitchCase="'product'">
				<ng-template #productValue let-value="value">
					<span [matTooltip]="value">{{ value }}</span>
				</ng-template>
				<ng-container
					*ngTemplateOutlet="
						productValue;
						context: {
							value: 
							row.articleDescription?.aggregated_info?.root && row.articleDescription?.aggregated_info?.brand ? 
									row.articleDescription.aggregated_info.root + ' - ' + row.articleDescription.aggregated_info.brand : 'category.article_without_category' | translate | sentencecase
						}
					"
				>
				</ng-container>
				<ng-container
					*ngTemplateOutlet="
						productValue;
						context: {
							value:
								row.articleDescription?.aggregated_info?.article
						}
					"
				>
				</ng-container>
			</div>
			<!-- Actions -->
			<div *ngSwitchCase="'actions'">
				<ng-container *ngIf="_configuration.configurationAction">
					<div class="actions-wrapper">
						<ng-container *ngIf="_configuration.order && _configuration.order.header.status === orderStatusEnum.DRAFT">
							<article-input-multiple-validation
								*ngIf="_configuration.configurationAction.validationMultiple"
								[article]="row"
								[organization]="_configuration.organization"
							>
							</article-input-multiple-validation>
						</ng-container>
						<div class="actions-buttons-wrapper">
							<button
								mat-icon-button
								*ngIf="
									_configuration.configurationAction.note &&
									_configuration.order &&
									_configuration.order.header.status === orderStatusEnum.DRAFT && configurationCustomerOrder.noteOrderVisibility[user.current_permission.context_application]
								"
								(click)="articleNoteChange($event, row)"
								[matTooltip]="
									(row.note_order ? 'comment.read' : 'comment.add') | translate | sentencecase
								"
							>
								<!-- TODO:
							- modifica icona
							- opacità dinamica in base a presenza/assenza nota -->
								<mat-icon [ngClass]="{ comment: row.note_order, add_comment: !row.note_order }">
									{{ row.note_order ? 'comment' : 'add_comment' }}
								</mat-icon>
							</button>
							<button
								*ngIf="
									_configuration.configurationAction.delete &&
									((_configuration.order && _configuration.order.header.status === orderStatusEnum.DRAFT) || !_configuration.order)
								"
								mat-icon-button
								[matTooltip]="'general.delete' | translate | sentencecase"
								(click)="onDeleteArticle.emit(row)"
							>
								<mat-icon class="delete">delete</mat-icon>
							</button>
							<button
								mat-icon-button
								*ngIf="
									_configuration.configurationAction.ticket &&
									_configuration.order.header.status !== orderStatusEnum.DRAFT &&
									user.current_permission.context_application !==
										contextApplicationItemCodeEnum.BACKOFFICE
								"
								(click)="
									ticketCenterMainService.dialogCreateNew({
										body: {
											custom_field: {
												request_type:
													ticketCenterBodyCustomField.RequestTypeEnum
														.NOT_CONFORMITY_REPORT,
												organization_code_item: _configuration.order.header.organization.code_item,
												organization_referent: user.first_name + ' ' + user.last_name,
												organization_referent_contact_address: user.username,
												order_code_item: _configuration.order._id,
												article_code_item: appStructure.Erp
													.has_erp
													? row.code_erp
													: row.code_item
											},
											reference: null,
											subject: null,
											message: null,
											attachment_list: null
										},
										id: null,
										request: null,
										status: null,
										thread: null
									})
								"
							>
								<mat-icon class="support_agent">support_agent</mat-icon>
							</button>
							<button
								*ngIf="_configuration.configurationAction.gallery && row.tableMetaInformation.hasGalleryImage"
								mat-icon-button
								(click)="dialogGallery(row)"
							>
								<mat-icon class="image">image</mat-icon>
							</button>

							<mat-icon
								*ngIf="
									_configuration.configurationAction.origin &&
									row.articleDescription.origin &&
									row.articleDescription.origin.code_item !== configurationCustomerArticle.originDefault
								"
								[matTooltip]="row.articleDescription.origin.description | sentencecase"
								class="public"
								>public</mat-icon
							>
						</div>
					</div>
				</ng-container>
			</div>
			<!-- Price Article -->
			<div *ngSwitchCase="'articlePrice.price'" class="fixed-height">
				<span>
					{{ row.articlePrice.price | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
				</span>
				<small
					*ngIf="row.tableMetaInformation?.priceWithDiscountDescription"
					class="cell-subtitle d-block"
				>
					{{ 'general.discounted' | translate }}:
					{{ row.tableMetaInformation.priceWithDiscountDescription }}
				</small>
			</div>
			<!-- Discount Agent -->
			<div *ngSwitchCase="'discount_agent'">
				<article-change-input
					[configuration]="{
						visible: true,
						value: row.discount_agent ? row.discount_agent.value : null,
						disabled:
							row.articleDescription.free_sample === 'S' ||
							!row.input_quantity ||
							!(_configuration.order && _configuration.order.header.status === orderStatusEnum.DRAFT),
						maxValue: 100,
						key: 'discount_agent',
						row: row,
						dataSubset: _configuration.localListHandlerData.dataSubset,
						organization: _configuration.organization,
						onArticleChange: onArticleChange,
						event: null,
						keyboardNavigationEnabled:
							configurationCustomerArticle.articleChangeInputKeyboardNavigationEnabled[
								user.current_permission.context_application
							],
						maskEnabled:
							configurationCustomerArticle.articleChangeInputMask[
								user.current_permission.context_application
							]
					}"
				></article-change-input>
			</div>
			<!-- Discount Article Free -->
			<div *ngSwitchCase="'qty_free'">
				<article-change-input
					[configuration]="{
						visible: !(row.articleDescription.free_sample === 'S' || !row.input_quantity),
						value: row.qty_free ? row.qty_free : null,
						disabled: !(_configuration.order && _configuration.order.header.status === orderStatusEnum.DRAFT),
						maxValue: row.input_quantity,
						key: 'qty_free',
						row: row,
						dataSubset: _configuration.localListHandlerData.dataSubset,
						organization: _configuration.organization,
						onArticleChange: onArticleChange,
						event: null,
						keyboardNavigationEnabled:
							configurationCustomerArticle.articleChangeInputKeyboardNavigationEnabled[
								user.current_permission.context_application
							],
						maskEnabled:
							configurationCustomerArticle.articleChangeInputMask[
								user.current_permission.context_application
							]
					}"
				></article-change-input>
			</div>
			<!-- Quantity Input -->
			<div *ngSwitchCase="'input_quantity'">
				<article-change-input
					*ngIf="row.tableMetaInformation"
					[configuration]="{
						visible: true,
						value: row.input_quantity ? row.input_quantity : null,
						disabled: !row.tableMetaInformation.inputQuantityDisable,
						key: 'input_quantity',
						row: row,
						dataSubset: _configuration.localListHandlerData.dataSubset,
						organization: _configuration.organization,
						onArticleChange: onArticleChange,
						event: null,
						keyboardNavigationEnabled:
							configurationCustomerArticle.articleChangeInputKeyboardNavigationEnabled[
								user.current_permission.context_application
							],
						maskEnabled:
							configurationCustomerArticle.articleChangeInputMask[
								user.current_permission.context_application
							],
						returnInputHighlightStatus: configurationCustomerArticle.articleChangeInputHighlight['input_quantity'],
						article: row
					}"
				></article-change-input>
			</div>
			<!-- Related Tester Quantity Input -->
			<div *ngSwitchCase="'articleDescription.relatedArticleTester.input_quantity'">
				<article-change-input
					[configuration]="{
						visible: row.articleDescription.relatedArticleTester ? true : false,
						value:
							row.articleDescription.relatedArticleTester &&
							row.articleDescription.relatedArticleTester.input_quantity
								? row.articleDescription.relatedArticleTester.input_quantity
								: null,
						disabled: false,
						key: 'input_quantity_related_tester',
						row: row,
						dataSubset: _configuration.localListHandlerData.dataSubset,
						organization: _configuration.organization,
						onArticleChange: onArticleChange,
						event: null,
						keyboardNavigationEnabled:
							configurationCustomerArticle.articleChangeInputKeyboardNavigationEnabled[
								user.current_permission.context_application
							],
						maskEnabled:
							configurationCustomerArticle.articleChangeInputMask[
								user.current_permission.context_application
							]
					}"
				></article-change-input>
				<small *ngIf="row.articleDescription.relatedArticleTester && user.current_permission.context_application !== contextApplicationItemCodeEnum.AGENT" class="cell-subtitle d-block">
					<!-- TODO: eventualmente ripristinare e trovare soluzione per non porre su più righe -->
					<!-- {{ 'article.field.sample_code' | translate | sentencecase }}: -->
					{{
						appStructure.erp_has_erp
							? row.articleDescription.relatedArticleTester.code_erp
							: row.articleDescription.relatedArticleTester.code_item
					}}
				</small>
			</div>
			<!-- calculate_price -->
			<div *ngSwitchCase="'calculate_price'" class="fixed-height">
				<!-- TODO: gestire currency dinamica -->
				<span>
					{{ row.calculate_price | utilPriceReturnItemValueFormattedPipe: configurationCustomerPrice.decimalDigit }}
				</span>
			</div>
			<!-- forecast -->
			<div *ngSwitchCase="'forecast'">
				<forecast-input-list
					[configuration]="
						{
							data: forecastConfigurationInputList,
							article: row
						}
					"
					(onForecastChange)="onForecastChange.emit({ code_item: row.code_item, data: $event })"
				>
				</forecast-input-list>
			</div>
			<!-- default -->
			<div
				*ngSwitchDefault
				articleTableWrapperCellDefaultHandler
				[configuration]="{ row: row, name: column.name }"
			></div>
		</ng-container>
	</div>
</ng-template>

<!-- TODO: valutare se ridondare la dinamica nelle due diverse celle code_item/erp serva a guadagnare qualcosa nelle
tempistiche di rendering -->
<ng-template #code_ let-code="code" let-row="row">
	<a *ngIf="_configuration.onItemSelectShow; else codeWithoutLink" (click)="onItemSelect.emit(row)" href="javascript:void(0)">
		<ng-container *ngTemplateOutlet="codeWithoutLink"></ng-container>
	</a>
	<ng-template #codeWithoutLink>{{ code }}</ng-template>
</ng-template>
