import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe } from '../../pipes/sentencecase.pipe';

@Injectable({
  providedIn: 'root',
})
export class UtilMatSnackBar {

  constructor(
    public snackBar: MatSnackBar,
    private sentencecasePipe: SentencecasePipe,
    private translateService: TranslateService
	) {}

  /**
   * Lancia uno snackBar interponendo il valore di `errorWithText` alle variabili ngx-translate
   * `saep_ict_angular_core.general.message.error.a_problem_has_occurred` e
   * `saep_ict_angular_core.general.message.error.data_may_not_have_been_saved`.
   * Le stinghe sono processate da `SentencecasePipe` e `TranslateService`
   *
   * @param {string} errorWithText : parte dinamica della frase (string o valore bundle multilingua)
   * @param {number} [duration] : durata dello snackbar (default 5s)
   * @param {string} [dismissButtonText] : testo del button di dismiss (string o valore bundle multilingua)
   * @memberof UtilMatSnackBar
   */
  errorWith(errorWithText: string, duration?: number, dismissButtonText?: string) {
    this.snackBar.open(
      this.sentencecasePipe.transform(
        `${this.translateService.instant('saep_ict_angular_core.general.message.error.a_problem_has_occurred')}
        ${this.translateService.instant(errorWithText)},
        ${this.translateService.instant('saep_ict_angular_core.general.message.error.data_may_not_have_been_saved')}`
      ),
      dismissButtonText ? this.translateService.instant(dismissButtonText) : null,
      {duration: duration ? duration : 5000}
    );
  }

}
