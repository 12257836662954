import { Routes } from '@angular/router';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { ReservedAreaGuard } from '../service/guard/reserved-area.guard';
import { PublicWrapperComponent } from '../frame/public-wrapper/public-wrapper.component';
import { OrderComponent } from '../page/commons/order/order.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { B2cOrganizationDetailComponent } from '../page/b2c/b2c-organization/b2c-organization-detail/b2c-organization-detail.component';
import { B2cOrganizationDetailFullDetailComponent } from '../page/b2c/b2c-organization/b2c-organization-detail/b2c-organization-detail-full-detail/b2c-organization-detail-full-detail.component';
import { UserComponent } from '../page/commons/user/user.component';
import { PermissionKeyEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { OrganizationDetailCommentComponent } from '../page/commons/organization/organization-detail/organization-detail-comment/organization-detail-comment.component';

export const routes: Routes = [
	{
		path: '',
		component: PublicWrapperComponent,
		canActivate: [ReservedAreaGuard],
		data: {
			permission: 'reserved',
			permissionKey: [PermissionKeyEnum.B2C_DASHBOARD]
		},
		children: [
			{
				path: '',
				redirectTo: ROUTE_URL.myAccount,
				pathMatch: 'full',
				data: { permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DETAIL] }
			},
			{
				path: ROUTE_URL.myAccount,
				component: B2cOrganizationDetailComponent,
				resolve: {
					meta: MetaDataResolver
				},
				data: {
					meta: {
						title: 'Il mio account',
						description: 'Descrizione a caso per testare la parte meta ma nella pagina Il mio account'
					},
					permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DETAIL]
				},
				children: [
					{
						path: '',
						redirectTo: ROUTE_URL.details,
						data: { permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DETAIL] }, // TOFIX
						pathMatch: 'full'
					},
					{
						path: ROUTE_URL.details,
						component: B2cOrganizationDetailFullDetailComponent,
						data: {
							permissionKey: [
								PermissionKeyEnum.B2C_ORGANIZATION_FULL_DETAIL,
								PermissionKeyEnum.B2C_CONTACT_LIST
							],
							icon: 'account_circle'
						}, // TOFIX
					},
					{
						path: ROUTE_URL.orders,
						component: OrderComponent,
						data: { 
							permissionKey: [PermissionKeyEnum.B2C_ORDER_LIST],
							icon: 'local_shipping'
						} // TOFIX
					},
					{
						path: ROUTE_URL.addresses,
						component: OrganizationDetailDestinationComponent,
						data: { 
							permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DESTINATION],
							icon: 'explore'
						} // TOFIX
					},
					{
						path: ROUTE_URL.userData,
						component: UserComponent,
						data: {
							permissionKey: [PermissionKeyEnum.B2C_PROFILE],
							icon: 'account_circle'
						} // TOFIX
					},
					{
						path: ROUTE_URL.comments,
						component: OrganizationDetailCommentComponent,
						data: { permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_COMMENT] },
						pathMatch: 'full'
					}
				]
			}
		]
		// loadChildren: () => import('../page/reserved-area/reserved-area.module').then(m => m.ReservedAreaModule),
		// canLoad: [ReservedAreaGuard]
	}
];


