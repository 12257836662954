import {
	ContextApplicationItemCodeEnum,
	ContextOtherItemCodeEnum
} from '../../enum/permission/context-application-item-code.enum';
import { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { PermissionKeyEnum } from '../../enum/permission/permission-key.enum';
import { AgentCustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { RestBaseGuid, RestBasePk } from '@saep-ict/angular-core';

export interface LoginAuthResponseModel {
	accessToken: string;
}

export class LoginAuthRequestModel {
	email: string;
	password: string;
}

export interface PermissionModel {
	key: string;
	description: string;
}

export interface UserActivateAccountRequestModel extends RestBasePk, RestBaseGuid {}
export interface VerifyGuidEmailRequestModel extends RestBaseGuid {
	email: string;
}

export interface VerifyGuidEmailResponseModel extends RestBasePk {
	type: string;
}

export interface UserActivationStateModel extends VerifyGuidEmailRequestModel, VerifyGuidEmailResponseModel {}

export interface LinkDetailModel {
	code: string | number;
  code_erp?: string;
	description?: string;
}

export interface ContextCodeAssociationItem {
	code: ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum;
	context_code_list: LinkDetailModel[] | AgentCustomerPouchModel[];
	context_code_list_all?: boolean;
}

export interface CurrentContextPermission {
	context_application: ContextApplicationItemCodeEnum;
	context_code: {
		code: string;
    code_erp?: string;
    description?: string;
		context_code_association_list: ContextCodeAssociationItem[];
	};
	permission: string[];
}

export interface UserDetailModel extends RestBasePk {
	is_active?: boolean;
	username?: string;
	permission?: string[];
	avatar?: string;
	first_name?: string;
	last_name?: string;
	context_application_list?: ContextPermission[];
	current_permission?: CurrentContextPermission;
}

export interface UserTypeContextModel {
	permission: PermissionKeyEnum;
	ambiguousRoute?: boolean;
	route: any;
	type: ContextApplicationItemCodeEnum;
	action?: ActionCreator<any, (props: any) => TypedAction<any>>;
	state?: MemoizedSelector<object, any, DefaultProjectorFn<any>>;
	description?: string;
	action_key?: string;
}

export class UserTypePermission implements UserTypeContextModel {
	permission: PermissionKeyEnum;
	ambiguousRoute?: boolean;
	route: any;
	type: ContextApplicationItemCodeEnum;

	constructor(data: UserTypeContextModel) {
		Object.keys(data).forEach(key => {
			this[key] = data[key];
		});
	}
}

export type UserDetailResponseModel = UserDetailModel;

export interface LinkCodeModel {
	code: string;
	context: ContextApplicationItemCodeEnum;
}

export interface ContextPermission extends LinkDetailModel {
	permission?: string[];
	context_code_list?: ContextPermission[];
}

export interface RestPostUserListPayload {
	user_list: UserDetailModel[];
}
export interface RestGetUserAvatarResponse {
	icon: string;
	user_id: number;
}
