import {ITdDataTableColumnCustom, LocalListHandlerBaseModel} from '@saep-ict/pouch_agent_models'

export namespace AttachmentListManager {
	export interface Configuration<D> {
		localListHandler: LocalListHandlerBaseModel<D>;
		page: {
			display?: {
				paginator?: boolean;
				textFilter?: boolean;
        tableHeader?: boolean;
			};
			columnList: ITdDataTableColumnCustom[];
			title: string;
		};
		upload?: {
			multiple?: boolean;
			acceptedType?: string;
      fileNameRegex?: RegExp;
      fileNameCharactersAccepted?: string;
      fileNameAllowDuplicate?: boolean;
      fileNameOnBucketCreate?: (fileName: string, timeStamp?: number) => string;
		};
		privilege: AttachmentListManager.Privilege;
    pathUrl: string;
    pathQueryParam?: string;
    customHeaders?: any
	}

	export interface Privilege {
		add?: boolean;
		delete?: boolean;
		download?: boolean;
	}

  export interface RestPath {
    path: string;
  }

  export interface RestQueryParam extends RestPath {
    path: string;
  }

  export interface RestDeletePayload {
    pathList: string[];
  }

  export interface RestResponseUploadMultipart {
    status: string;
    id: string;
    data: {
      file: string;
      path: string;
    }
  }
}
