<drop-area-file
	*ngIf="dropAreaEnabled"
	[multiple]="multiple"
	[acceptedType]="acceptedType"
	(filesEmitter)="changeFiles($event)"
>
</drop-area-file>
<ng-container *ngIf="files.length > 0">
	<div *ngFor="let row of files; let i = index" class="row file-selected-container">
		<!-- <div *ngIf="row.preview; else noPreview" class="col-md-3">
			<div [style.background-image]="'url(' + row.preview.toString() + ')'" class="img-placeholder"></div>
		</div> -->
		<div class="file-selected col-md-10" [matTooltip]="row.name">
			<mat-icon>attach_file</mat-icon>
			{{ row.name }}
		</div>
		<div class="file-selected delete-button col-md-2">
			<button mat-icon-button color="warn" (click)="deleteFile(i)">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
		<div class="col-md-12">
			<!-- <mat-progress-bar
				*ngIf="i === 0 && uploadService.uploadInProgressObservable | async as upload"
				[mode]="upload.state == 'PENDING' ? 'buffer' : (upload.state == 'DONE' || upload.progress == 100 ? 'indeterminate' : 'determinate')"
				[value]="upload.progress"
			> -->
			<mat-progress-bar
				*ngIf="i === 0 && uploadService.uploadInProgressObservable | async as upload"
				[mode]="upload.state == 'PENDING' || upload.state == 'DONE' || upload.progress == 100 ? 'indeterminate' : 'determinate'"
				[value]="upload.progress"
			>
			</mat-progress-bar>
		</div>
	</div>
</ng-container>
