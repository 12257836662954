import { OrganizationLevelEnum } from '@saep-ict/pouch_agent_models';
import { ConfigurationProjectModel, PATH_URL, ROUTE_URL, PermissionKeyEnum } from '@saep-ict/angular-spin8-core';
import _ from "lodash";

export const list: ConfigurationProjectModel.Navigation.MainSidebarItemModel[] = [
	{
		key: 'dashboard',
		name: 'saep_ict_angular_spin8_core.page.dashboard.name',
		icon: 'custom-dashboard',
		iconCustom: true,
		route: `${PATH_URL.PRIVATE}`,
		position: 1,
		permission: PermissionKeyEnum.CRM_DASHBOARD
	},
	{
		key: 'organizations',
		name: 'saep_ict_angular_spin8_core.page.organization.name_plural',
		icon: 'custom-business',
		iconCustom: true,
		route: ``,
		position: 1,
		permission: PermissionKeyEnum.CRM_ORGANIZATION_LIST
	},
	{
		key: 'organizations_lead',
		parentKey: 'organizations',
		name: 'saep_ict_angular_spin8_core.page.organization.level.lead',
		icon: '',
		route: `/${ROUTE_URL.organizations}/${OrganizationLevelEnum.LEAD}`,
		position: 1,
		permission: PermissionKeyEnum.CRM_ORGANIZATION_LIST
	},
	{
		key: 'organizations_prospect',
		parentKey: 'organizations',
		name: 'saep_ict_angular_spin8_core.page.organization.level.prospect',
		icon: '',
		route: `/${ROUTE_URL.organizations}/${OrganizationLevelEnum.PROSPECT}`,
		position: 2,
		permission: PermissionKeyEnum.CRM_ORGANIZATION_LIST
	},
	{
		key: 'organizations_customer',
		parentKey: 'organizations',
		name: 'saep_ict_angular_spin8_core.page.organization.level.customer',
		icon: '',
		route: `/${ROUTE_URL.organizations}/${OrganizationLevelEnum.CUSTOMER}`,
		position: 3,
		permission: PermissionKeyEnum.CRM_ORGANIZATION_LIST
	},
	{
		key: 'contacts',
		name: 'saep_ict_angular_spin8_core.page.contact.name_plural',
		icon: 'custom-contact_page',
		iconCustom: true,
		route: `${ROUTE_URL.contacts}`,
		position: 3,
		permission: PermissionKeyEnum.CRM_CONTACT_LIST
	},
	// {
	// 	key: 'activity',
	// 	name: 'sidenav.activities',
	// 	icon: 'filter_alt',
	// 	route: ``,
	// 	position: 4,
	// 	permission: PermissionKeyEnum.CRM_DASHBOARD
	// },
	{
		key: 'request_list',
		// parentKey: 'activity',
		name: 'saep_ict_angular_spin8_core.page.request.name_plural',
		icon: 'all_inbox',
		// route: `${ROUTE_URL.opportunity}/${OpportunityLevelEnum.LEAD}`,
		route: `${ROUTE_URL.opportunity}/LEAD`,
		position: 4,
		permission: PermissionKeyEnum.CRM_OPPORTUNITY_LIST
	},
	{
		key: 'opportunity',
		// parentKey: 'activity',
		name: 'opportunity.name',
		icon: 'custom-lightbulb',
		iconCustom: true,
		route: `${ROUTE_URL.opportunityKanban}/`,
		position: 4,
		permission: PermissionKeyEnum.CRM_OPPORTUNITY_LIST
	},
	{
		key: 'offers',
		name: 'offer.name_plural',
		icon: 'custom-checklist',
		iconCustom: true,
		route: ROUTE_URL.offer,
		position: 5,
		permission: PermissionKeyEnum.CRM_OFFER_LIST
	},
	// {
	// 	key: 'offers_draft',
	// 	parentKey: 'offers',
	// 	name: 'order.status.DRAFT',
	// 	icon: 'assignment',
	// 	route: `/${ROUTE_URL.offer}/${OrderStatusEnum.DRAFT.toLowerCase()}`,
	// 	position: 5,
	// 	permission: PermissionKeyEnum.CRM_OFFER_LIST
	// },
	// {
	// 	key: 'offers_readytosend',
	// 	parentKey: 'offers',
	// 	name: 'order.status.READY_TO_SEND',
	// 	icon: 'assignment',
	// 	route: `/${ROUTE_URL.offer}/${OrderStatusEnum.READY_TO_SEND.toLowerCase()}`,
	// 	position: 5,
	// 	permission: PermissionKeyEnum.CRM_OFFER_LIST
	// },
	// {
	// 	key: 'offers_consolidated',
	// 	parentKey: 'offers',
	// 	name: 'order.status.CONSOLIDATED',
	// 	icon: 'assignment',
	// 	route: `/${ROUTE_URL.offer}/${OrderStatusEnum.CONSOLIDATED.toLowerCase()}`,
	// 	position: 5,
	// 	permission: PermissionKeyEnum.CRM_OFFER_LIST
	// },

	{
		key: 'receipts',
		name: 'saep_ict_angular_spin8_core.page.invoice.name',
		icon: 'custom-content_copy',
		iconCustom: true,
		route: `${ROUTE_URL.receipts}`,
		position: 5,
		permission: PermissionKeyEnum.CRM_INVOICE_LIST
	},
	{
		key: 'action',
		name: 'action.plural_name',
		icon: 'custom-category',
		iconCustom: true,
		route: `${ROUTE_URL.actions}`,
		position: 6,
		permission: PermissionKeyEnum.CRM_OPPORTUNITY_ACTIVITY
	},
	{
		key: 'log',
		name: 'log.name',
		icon: 'custom-logo_dev',
		iconCustom: true,
		route: `${ROUTE_URL.logs}`,
		position: 7,
		permission: PermissionKeyEnum.CRM_DASHBOARD
	}
];