export const company_variables = {
	title: 'SPIN8 | SAEP ICT Engineering',
	copyright_owner: 'SAEP ICT Engineering s.r.l.',
	logo_path: {
		header: 'assets/theme-current/img/company-logo/logo.png',
		footer: 'assets/common/img/email/logo-spin8-generic.png'
	},
	color: {
		primary: '#66CC33',
		accent: '#FF6600'
	},
	company: {
		address: ['Via Ascanio Sforza, 87', '29141 Milano (MI)', 'Italy'],
		email: 'spin8@spin8.com',
		name: 'Spin8',
		pec: 'spin8_pec@spin8.com',
		phone: '8888888888',
		vat: '12345678904'
	}
};
