import { ContextApplicationItemCodeEnum, PermissionKeyEnum, ROUTE_URL, UserTypeContextModel } from '@saep-ict/angular-spin8-core';
import * as BackofficeRouteList from '../../../router/backoffice-routing';
import * as B2CRouteList from '../../../router/b2c-routing';

export const routingPermissionTypeList: UserTypeContextModel[] = [
	{
		permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
		route: BackofficeRouteList.routes,
		type: ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN,
		description: 'Backoffice Area (Admin)',
		action_key: 'backoffice_admin'
	},
	{
		permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
		route: BackofficeRouteList.routes,
		type: ContextApplicationItemCodeEnum.BACKOFFICE,
		description: 'Backoffice Area',
		action_key: 'backoffice'
	},
	{
		permission: PermissionKeyEnum.B2C_DASHBOARD,
		route: B2CRouteList.routes,
		type: ContextApplicationItemCodeEnum.B2C,
		description: 'B2C Area',
		action_key: 'organization'
	}
];

export const returnPublicNavigationCondition = (
    permissionContext: ContextApplicationItemCodeEnum[],
    currentUrl: string
): boolean => {
    return false
}

export const enableNavigationForHomepage = false;