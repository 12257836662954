import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import {
	BaseStateModel,
	ListWrapperComponent,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { ArticleListFilterModel } from '../../../../../../service/pouch-db/filter/article-list-filter.model';
import * as ConfigurationCustomerArticle from '../../../../../../constants/configuration-customer/article/article.constant';
import * as ConfigurationCustomerOrder from '../../../../../../constants/configuration-customer/order/order.constant';
import * as ConfigurationCustomerAppStructure from '../../../../../../constants/configuration-customer/app-structure/app-structure.constant';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../../../state';
import { ArticleActionEnum } from '../../../../../../state/article/article.actions';
import { OrganizationActionEnum } from '../../../../../../state/organization/organization.actions';
import { OrderActionEnum } from '../../../../../../state/order/order.actions';
import { CategoryListActionEnum } from '../../../../../../state/category-list/category-list.actions';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { delay, filter, map, mergeMap, take } from 'rxjs/operators';
import { UtilOrderService } from '../../../../../../service/util/util-order.service';
import { ArticlePouchModel, DivisionPouchModel } from '@saep-ict/pouch_agent_models';
import { AppUtilService } from '../../../../../../service/util/app-util.service';
import {
	OrderRowModel,
	OrderStateModel,
	OrganizationStateModel,
	UserDetailModel,
	OrderEnum,
	ROUTE_URL,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-spin8-core';
import { LocalListHandlerBaseLocalPagination, LocalListHandlerBaseModel, Category, CategoryMap } from '@saep-ict/pouch_agent_models';
import { UtilCategoryListService } from '../../../../../../service/util/util-category-list.service';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import * as OrderCommonCatalogEnum from '../../../../../../enum/order-common-catalog.enum';
import * as CategoryModel from '../../../../../../model/category-list.model';
import * as ConfigurationCategory from '../../../../../../constants/category.constant';
import { ArticleTableConfigurationAction } from '../../../../../../model/article.model';
import { UtilArticleService } from '../../../../../../service/util/util-article.service';

@Component({
	selector: 'app-order-detail-catalog',
	templateUrl: './order-detail-catalog.component.html',
	styleUrls: ['./order-detail-catalog.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailCatalogComponent implements OnDestroy {
	@ViewChild('listWrapper') public listWrapper: ListWrapperComponent;
	@ViewChild('scrollbarRef') scrollbarRef: ElementRef;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;
	orderForArticleStructure: OrderStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];
	listPageBaseData: LocalListHandlerBaseModel<ArticlePouchModel, CategoryModel.List>;

	categoryList$: Observable<BaseStateModel<CategoryMap, ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	categoryList: Category[];
	/**
	 * Lista categorie utilizzate nella renderizzazione delle diverse tipologie dei componenti di filtro
	 *
	 * @type {Category[]}
	 * @memberof OrderDetailCatalogComponent
	 */
	categoryListParseVisualization: Category[];

	isMobile = false;
	configurationCustomerOrder = ConfigurationCustomerOrder;
	orderEnum = OrderEnum;
	orderCommonCatalogEnum = OrderCommonCatalogEnum;
	sideBarPositionValues = SideBarPositionValues;

	// TODO: rimuovere | string
	/**
	 * Determina la scelta dela struttura articoli nell'´ngSwitch` incluso in `#articleStructure`
	 *
	 * @type {(OrderEnum.CommonArticleStructure | string)}
	 * @memberof OrderDetailCatalogComponent
	 */
	articleStructureKey: OrderEnum.CommonArticleStructure | string;

	articleTableConfigurationAction: ArticleTableConfigurationAction = {
		gallery: true,
		validationMultiple: true,
		origin: true
	}

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private utilService: AppUtilService,
		public utilOrderService: UtilOrderService,
		private utilCategoryListService: UtilCategoryListService,
		private utilArticleService: UtilArticleService
	) {
		this.utilOrderService.orderChangeTemp = null;
		this.utilOrderService.updateArticle = true;
		this.loadStaticData();
		this.subscribeManagerInit();
		this.isMobile = window.innerWidth < 1200;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		console.log(this.user)
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe({ error: (e) => { console.log(`data: something went wrong`, e); }}),
			'data'
		);
	}

	// subscribe
	initMandatoryData() {
		let hasDiff: boolean;
		return this.organization$.pipe(
			delay(0),
			filter(
				(organization: BaseStateModel<OrganizationStateModel>) =>
					organization && organization.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationStateModel>) => {
				if (organization.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (organization.data) {
					this.organization = organization.data;
					// if (IS_MULTIDIVISION) {
					// 	// TODO - gestire multidivision
					// } else {
					// }

					return this.order$;
				}
			}),
			filter(
				(order: BaseStateModel<OrderStateModel>) =>
					this.organization &&
					order &&
					order.type !== OrderActionEnum.LOAD &&
					order.type !== OrderActionEnum.DISABLE_SAVE_PUT
			),
			mergeMap((order: BaseStateModel<OrderStateModel>) => {
				switch (order.type) {
					case OrderActionEnum.UPDATE: {
						const cloneOrder = _.cloneDeep(this.order);
						this.order = order.data;
						// TODO: approfondire hasDiff
						hasDiff = this.orderDiff(cloneOrder, this.order);
						break;
					}
				}
				return this.articleList$;
			}),
			filter(
				(articleList: BaseStateModel<ArticlePouchModel[]>) =>
					this.utilOrderService.updateArticle && articleList && articleList.type !== ArticleActionEnum.LOAD_FROM_RECAP
			),
			mergeMap((articleList: BaseStateModel<ArticlePouchModel[]>) => {
				this.orderForArticleStructure = _.cloneDeep(this.order);
				if (articleList.type === ArticleActionEnum.ERROR) {
					throw new Error(ArticleActionEnum.ERROR);
				}
				if (articleList.type === ArticleActionEnum.UPDATE) {
					const division: string = this.organization.division_list
						? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
								.division
						: null;
					this.articleList = this.utilOrderService.formatOrderCatalogArticleList(
						articleList.data,
						division,
						this.order
					);
					this.utilOrderService.updateArticle = false;
				}
				return this.categoryList$;
			}),
			filter(
				(categoryListState: BaseStateModel<CategoryMap, ArticleListFilterModel>) =>
					categoryListState && categoryListState.type !== CategoryListActionEnum.LOAD_ALL
			),
			map(async (categoryListState: BaseStateModel<CategoryMap, ArticleListFilterModel>) => {
				if (categoryListState.type === CategoryListActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				this.categoryList =
					categoryListState.type !== CategoryListActionEnum.NOT_EXISTING ?
					categoryListState.data.tree :
					[];
				this.order = await this.utilArticleService.updateOrderOnArticleRecapChange(
					this.order,
					this.articleList,
					this.organization
				);
				this.categoryListParseVisualization =
					await ConfigurationCustomerOrder.commonCatalogReturnNestedCategoryParse(this.user, this.categoryList);
				const selectLevelBy: CategoryModel.SelectLevelBy =
					ConfigurationCustomerOrder.commonCatalogCategoryStructure === OrderCommonCatalogEnum.CategoryStructure.TAB_ON_TOP ?
					_.cloneDeep(
						ConfigurationCustomerOrder.commonCatalogCategoryTabOnTopConfiguration.initialization.selectLevelBy
					) :
					null;
				const categorySelectBy: Category =
					await ConfigurationCategory.returnCategorySelectBy(selectLevelBy, this.categoryList);
				this.listPageBaseData = {
					pageName: 'order-detail',
					// TODO: portare in configurazione, diviso per context app il pageSize
					pagination:
						(
							ConfigurationCustomerOrder.commonCatalogArticleStructure === this.orderEnum.CommonArticleStructure.ACCORDION ||
							(
								ConfigurationCustomerOrder.commonCatalogArticleStructure === this.orderEnum.CommonArticleStructure.PLAIN_LIST &&
								ConfigurationCustomerOrder.commonCatalogVirtualScrollConfiguration.enabled
							)
						) ?
						null : // per ora la modalità accordion non prevede la paginazione
						{ pageSize: 100, pageIndex: null, length: null },
					filters: {
						localSearchText: {
							value: null,
							key_list: ConfigurationCustomerAppStructure.erp_has_erp
								? [
										'articleDescription.language_list.description',
										'code_erp',
										'articleDescription.relatedArticleTester.code_erp'
								]
								: [
										'articleDescription.language_list.description',
										'code_item',
										'articleDescription.relatedArticleTester.code_item'
								]
						},
						// nuovo meccanismo di notifica della categoria selezionata che permette agli
						// article-structure-wrapper `ACCORDION` e `CATEGORY_TREE` di organizzare i template
						customFilters: {
							categoryList: [categorySelectBy]
						}
					},
					sort: {
						name: 'articleDescription.sequence',
						order: TdDataTableSortingOrder.Ascending
					},
					sortRemapObject: {
						discount_agent: 'discount_agent.value'
					},
					columnList:
						ConfigurationCustomerArticle.orderColumnList(
							this.order.header.currency.description_short,
							ROUTE_URL.catalog
						)
						[this.user.current_permission.context_application],
					data:
						this.utilCategoryListService.returnArticleListFilteredByCategoryList(
							this.articleList,
							categorySelectBy ? [categorySelectBy.code_item] : []
						)
				};
				this.articleStructureSelectByCategory(categorySelectBy);
			})
		);
	}

	async categorySelectedChange(e: Category) {
		this.listPageBaseData.data = e.code_item
			? this.utilCategoryListService.returnArticleListFilteredByCategoryList(this.articleList, [e.code_item])
			: this.articleList;
		// la categoria emessa dall'evento appartiene all'array `categoryListParseVisualization`, dunque potrebbe non contenere
		// gli annidamenti utili per la renderizzazione della struttura articoli selezionata a valle del processo.
		// `categorySelected` viene recuperato dunque dall'omnicomprensivo `categoryList`
		const categorySelected: Category =
			await ConfigurationCategory.returnCategorySelectByPropertyValue(this.categoryList, 'code_item', e.code_item);
		this.listPageBaseData.filters.customFilters.categoryList = categorySelected ? [categorySelected] : [];
		this.articleStructureSelectByCategory(categorySelected);
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
		this.scrollToTop();
	}

	scrollToTop() {
		this.scrollbarRef.nativeElement.scrollIntoView();
	}

	orderDiff(cloneOrder: OrderStateModel, order: OrderStateModel): boolean {
		let hasDiff = true;
		if (cloneOrder) {
			// const result = <OrderStateModel>this.utilService.deepDiffMapper(cloneOrder, order);
			// if (
			// conditions...
			// ) {
			// 		hasDiff = false;
			// } else {
			hasDiff = false;
			// }
		}
		return hasDiff;
	}

	onArticleChangeHandler(e: OrderRowModel) {
		const i = this.utilService.getElementIndex(this.articleList, 'code_item', e.row.code_item);
		this.articleList[i] = e.row;
		this.utilOrderService.changeOrderArticle(e, this.order, this.organization, false);
	}

	/**
	 * Permete lo switch tra diversi componenti di (article-list-structure-wrapper)
	 * frontend/src/app/widget/article/article-list-structure-wrapper
	 * 
	 * Essendo la tipologia `ACCORDION` ha un funzonamento fortemente vincolato alla tipologia di level
	 * (`ConfigurationCustomerOrder.commonCatalogArticleStructureAccordionLevel`)
	 * in caso la categoria selezionata abbia level differente, il metodo riorganizza gli articoli secondo
	 * la struttura semplice `PLAIN_LIST`, attraverso `articleStructureKey`
	 * 
	 * @param category 
	 */
	articleStructureSelectByCategory(category: Category) {
		const paginationDefault: LocalListHandlerBaseLocalPagination = { pageSize: 100, pageIndex: null, length: null };
		switch (ConfigurationCustomerOrder.commonCatalogArticleStructure) {
			case OrderEnum.CommonArticleStructure.ACCORDION:
				if (category.level === ConfigurationCustomerOrder.commonCatalogArticleStructureAccordionLevel) {
					this.articleStructureKey = OrderEnum.CommonArticleStructure.ACCORDION;
					delete this.listPageBaseData.pagination;
				} else {
					this.articleStructureKey = _.cloneDeep(OrderEnum.CommonArticleStructure.PLAIN_LIST);
					if (ConfigurationCustomerOrder.commonCatalogVirtualScrollConfiguration.enabled) {
						delete this.listPageBaseData.pagination;
					} else {
						this.listPageBaseData.pagination = paginationDefault;
					}
				}
				break;
			case OrderEnum.CommonArticleStructure.PLAIN_LIST:
				this.articleStructureKey = _.cloneDeep(ConfigurationCustomerOrder.commonCatalogArticleStructure);
				if (ConfigurationCustomerOrder.commonCatalogVirtualScrollConfiguration.enabled) {
					delete this.listPageBaseData.pagination;
				} else {
					this.listPageBaseData.pagination = paginationDefault;
				}
				break;
			default:
				this.articleStructureKey = _.cloneDeep(ConfigurationCustomerOrder.commonCatalogArticleStructure);
				this.listPageBaseData.pagination = paginationDefault;
		}
	}

}