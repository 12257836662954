import { DestinationPouchModel, OrderAddressPouchModel } from '@saep-ict/pouch_agent_models';
import { TitleCasePipe } from '@angular/common';
import { Injector } from '@angular/core';

const injector = Injector.create({
	providers: [
		{ provide: TitleCasePipe, deps: [] }
	]
});

const titleCasePipe = injector.get(TitleCasePipe);

export const getFormattedAddress = (e: DestinationPouchModel | OrderAddressPouchModel): string => {
    let address;
    if (e.address && typeof e.address !== 'string') {
        address = e.address;
    } else {
        address = e;
    }
    let formattedAddress = '';
    if (address) {
        formattedAddress =
            (address.locality ? address.locality + ', ' : '-') +
            (address.zip_code ? address.zip_code + ' - ' : '') +
            (address.address ? titleCasePipe.transform(address.address) : '-');
    }
    return formattedAddress;
}