import { ConfigurationProjectModel, UserDetailModel, PermissionKeyEnum, PATH_URL, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import _ from "lodash";

export const returnList = (
	user: UserDetailModel
): ConfigurationProjectModel.Navigation.MainSidebarItemModel[] => {
	const map: ConfigurationProjectModel.Navigation.MainSidebarItemModel[] = [
		{
			key: 'dashboard',
			name: 'saep_ict_angular_spin8_core.page.dashboard.name',
			icon: 'custom-dashboard',
			iconCustom: true,
			route: `${PATH_URL.PRIVATE}`,
			position: 1,
			permission: PermissionKeyEnum.B2B_DASHBOARD
		},
		{
			key: 'company',
			name: 'saep_ict_angular_spin8_core.page.organization.name',
			icon: 'custom-tune',
			iconCustom: true,
			route: `${ROUTE_URL.company}/${user.current_permission.context_code.code}`,
			position: 2,
			permission: PermissionKeyEnum.B2B_ORGANIZATION_DETAIL
		},
		{
			key: 'orders',
			name: 'saep_ict_angular_spin8_core.page.order.name_plural',
			icon: 'custom-inbox',
			iconCustom: true,
			route: '',
			position: 3,
			permission: PermissionKeyEnum.B2B_ORDER_LIST
		},
		{
			key: 'orders_draft',
			parentKey: 'orders',
			name: 'saep_ict_angular_spin8_core.status.order.DRAFT',
			icon: '',
			route: `/${ROUTE_URL.orders}/draft`,
			position: 1,
			permission: PermissionKeyEnum.B2B_ORDER_LIST
		},
		{
			key: 'orders_sending',
			parentKey: 'orders',
			name: 'saep_ict_angular_spin8_core.status.order.SENDING',
			icon: '',
			route: `/${ROUTE_URL.orders}/sending`,
			position: 2,
			permission: PermissionKeyEnum.B2B_ORDER_LIST
		},
		{
			key: 'orders_consolidated',
			parentKey: 'orders',
			name: 'saep_ict_angular_spin8_core.status.order.CONSOLIDATED',
			icon: '',
			route: `/${ROUTE_URL.orders}/consolidated`,
			position: 3,
			permission: PermissionKeyEnum.B2B_ORDER_LIST
		},
		{
			key: 'orders_archived',
			parentKey: 'orders',
			name: 'saep_ict_angular_spin8_core.status.order.FULFILLED',
			icon: '',
			route: `/${ROUTE_URL.orders}/fulfilled`,
			position: 4,
			permission: PermissionKeyEnum.B2B_ORDER_LIST
		},
		{
			key: 'receipts',
			name: 'saep_ict_angular_spin8_core.page.invoice.name',
			icon: 'custom-content_copy',
			iconCustom: true,
			route: `${ROUTE_URL.receipts}`,
			position: 4,
			permission: PermissionKeyEnum.B2B_INVOICE_LIST
		},
		{
			key: 'media-center',
			name: 'saep_ict_angular_spin8_core.page.media_center.name',
			icon: 'custom-folder-2',
			iconCustom: true,
			route: `${ROUTE_URL.mediaCenter}/mediacenter`,
			position: 5,
			permission: PermissionKeyEnum.B2B_MEDIACENTER
		},
		{
			key: 'ticket_center',
			name: 'saep_ict_angular_spin8_core.page.ticket_center.name',
			icon: 'custom-confirmation_number',
			iconCustom: true,
			route: `${ROUTE_URL.ticketCenter}`,
			position: 6,
			permission: PermissionKeyEnum.B2B_TICKET_CENTER
		},
		{
			key: 'news',
			name: 'saep_ict_angular_spin8_core.page.news.name',
			icon: 'custom-insert_drive_file',
			iconCustom: true,
			route: ROUTE_URL.news,
			position: 7,
			permission: PermissionKeyEnum.B2B_NEWS
		},
		{
			key: 'survey_center_forecast',
			name: 'saep_ict_angular_spin8_core.page.survey_center.forecast',
			icon: 'custom-pie_chart',
			iconCustom: true,
			route: ROUTE_URL.forecast,
			position: 8,
			permission: PermissionKeyEnum.B2B_SURVEY_CENTER_FORECAST
		},
		{
			key: 'reports',
			name: 'saep_ict_angular_spin8_core.page.reports.name',
			icon: 'custom-bar_chart',
			iconCustom: true,
			route: ROUTE_URL.reports,
			position: 9,
			permission: PermissionKeyEnum.B2B_REPORTS
		},
		{
		key: 'asset_request_ticket_list_image_video_euroitalia',
		name: 'Image/Video Euroitalia',
		icon: 'custom-campaign',
		iconCustom: true,
		route: `${ROUTE_URL.assetRequestTicketList}/${ROUTE_URL.assetRequestCenter}`,
		position: 10,
		permission: PermissionKeyEnum.B2B_ASSET_REQUEST_CENTER
		},
	];
	return map;
};