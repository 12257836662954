import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';


/**
 * GTM: pushing evento aggiunta articolo al carrello
 * @param article 
 * @param order 
 */
export const trackArticleAddedToCart = (article: ArticlePouchModel, order: OrderStateModel) => {
    window['dataLayer'].push({
        event: 'add_to_cart',
        order: order.code_item,    
        ecommerce:{
            items:[
                {
                    item_id: article.code_erp ?? article.code_item,
                    item_name: article.articleDescription.language_list[0].description || null,
                    currency: 'EUR',            
                    price: article.articlePrice.price,
                    quantity: article.input_quantity
                }
            ]}
    })
}