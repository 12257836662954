import { ArticlePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import * as ConfigurationCustomerOrder from '../order/order.constant';

export const returnArticleAvailability = (
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): boolean => {
    let isAvailable = true;
    if (!organization.article_recap_code_item) {
        if (
            article.articleDescription.stock &&
            Object.prototype.hasOwnProperty.call(article.articleDescription.stock, 'is_available') &&
            article.articleDescription.stock.is_available === false
        ) {
            const availableQuantity = returnArticleAvailableQuantity(article, organization);
            if (availableQuantity !== null) {
                isAvailable = availableQuantity ? true : false;
            }
            if (isAvailable && article.ordered_quantity) {
                const quantityCanBeOrdered =
                    returnArticleAvailableQuantityConsideringCurrentOrder(
                        article.ordered_quantity,
                        article,
                        organization
                    );
                isAvailable = quantityCanBeOrdered ? true : false;
            }
        }
    }
    return isAvailable;
}

export const returnArticleAvailableQuantityConsideringCurrentOrder = (
    inputQuantity: number,
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): number => {
    let quantityCanBeOrdered: number = inputQuantity;
    const availableQuantity: number = returnArticleAvailableQuantity(article, organization);
    if (availableQuantity || availableQuantity === 0) {
        const quantityAlreadyOrdered: number = article.ordered_quantity || 0;
        quantityCanBeOrdered = availableQuantity - quantityAlreadyOrdered;
    }
    return quantityCanBeOrdered;
}

export const returnArticleAvailableQuantity = (
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): number => {
    const maxKgForNotAvailable = 2000;
    // nel progetto sono sparsi controlli sul return di questo metodo `!= null`
    let availableQuantity: number = null;
    if (
        !organization.article_recap_code_item &&
        article &&
        article.articleDescription &&
        article.articleDescription.stock &&
        Object.prototype.hasOwnProperty.call(article.articleDescription.stock, 'is_available') &&
        article.articleDescription.stock.is_available === false &&
        article.articleDescription.weight // esclude anche valore 0
    ) {
        // l'arrotondamento dei decimali per difetto potrebbe essere in contro tendenza con l'arrotondamento
        // per eccesso ad uso di `returnArticleQuantityByQtyBoxMultiple()`
        availableQuantity = Math.floor(maxKgForNotAvailable / article.articleDescription.weight);

        const multipleOfTotalQuantity =
            returnArticleQuantityByQtyBoxMultiple(availableQuantity, article, organization);

        if (
            multipleOfTotalQuantity > availableQuantity &&
            article.articleDescription.qty_box
        ) {
            availableQuantity = multipleOfTotalQuantity - article.articleDescription.qty_box;
        }
    }
    return availableQuantity;
}

/**
 * Restituisce il multiplo di qty_box più vicino (per eccesso) all'input dell'utente.
 * In base alle configurazioni di progetto il metodo potrebbe bypassare 1 come valore di default. Si veda:
 * - ConfigurationCustomerOrder.qtyBoxMultipleCheck
 *
 * @param {*} value
 * @param {ArticlePouchModel} article
 * @param {OrganizationPouchModel} organization
 * @returns {number}
 * @memberof UtilOrderService
 */
export const returnArticleQuantityByQtyBoxMultiple = (
    value,
    article: ArticlePouchModel,
    organization: OrganizationPouchModel
): number => {
    let coefficient: number;
    if (
        (article.articleDescription.qty_box &&
            organization &&
            ConfigurationCustomerOrder.qtyBoxMultipleCheck[organization.organization_type]) ||
        (!organization && ConfigurationCustomerOrder.qtyBoxMultipleCheck.PUBLIC)
    ) {
        coefficient = article.articleDescription.qty_box;
    } else {
        coefficient = 1;
    }
    if (value > 0) {
        value = Math.ceil(value / coefficient) * coefficient;
    } else if (value < 0) {
        value = Math.floor(value / coefficient) * coefficient;
    }
    return value;
}