import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  EMPTY,
  Observable,
  Subject,
  Subscription,
  finalize,
} from 'rxjs';
import { FileToUpload, Upload, upload } from './upload';
import {
  APP_CONFIG_TOKEN,
  IMediaCenterAppConfig,
} from '../../interface/media-center-app-config.interface';
import { UtilService } from '../util.service';

@Injectable({ providedIn: 'root' })
export class UploadService implements OnDestroy {
  private progressSubscription: Subscription;
  private cachedObservable$: Subject<Upload>;
  private uploadCompleted$: Subject<FileToUpload>;
  private files: FileToUpload[] = [];

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG_TOKEN) protected appConfig: IMediaCenterAppConfig,
    protected utils: UtilService
  ) {}

  get hasFilesToUpload(): boolean {
    return this.files.length > 0;
  }

  get isUploadInProgress(): boolean {
    return this.files.length > 0 && this.cachedObservable$ != null;
  }

  get fileList(): FileToUpload[] {
    return this.files;
  }

  get uploadInProgressObservable(): Observable<Upload> {
    return this.cachedObservable$
//      ? this.cachedObservable$.asObservable()
//      : EMPTY;
  }

  ngOnDestroy() {
    this.progressSubscription.unsubscribe();
  }

  addFileToUpload(
    file: File,
    currentPath: string | undefined | null
  ) {
    const fileToUpload = { file: file, remotePath: currentPath };
    this.files.push(fileToUpload);
  }

  startUpload(): Observable<FileToUpload> {
    if (this.uploadCompleted$) {
      return this.uploadCompleted$;
    } else if (this.files.length > 0) {
      this.uploadCompleted$ = new Subject();
      return this.upload();
    } else {
      return EMPTY;
    }
  }

  private upload(): Observable<FileToUpload> {
    if (!this.files?.length) {
      this.uploadCompleted$.complete();
      this.uploadCompleted$ = null;
      return EMPTY;
    }

    const fileToUpload = this.files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload.file);
    if (fileToUpload.remotePath) {
      formData.append('path', fileToUpload.remotePath);
    }

    this.cachedObservable$ = new Subject<Upload>();
    const obs = this.http
      .post(this.getPathPrefix() + '/upload-multipart', formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        upload(fileToUpload),
        finalize(() => {
          this.cachedObservable$.complete();
          this.cachedObservable$ = null;
          this.removeFileFromList(fileToUpload);
          this.uploadCompleted$.next(fileToUpload);
          this.upload();
        })
      );

    this.progressSubscription = obs.subscribe(this.cachedObservable$);

    return this.uploadCompleted$;
  }

  cancelUpload(index: number) {
    this.files.splice(index, 1);
    if (index == 0 && this.progressSubscription && !this.progressSubscription.closed) {
      this.progressSubscription.unsubscribe();
    }
  }

  private getPathPrefix() {
    let urlPrefix = this.appConfig.mc_config.be_url;
    if (this.appConfig.mc_config.suffix) {
      urlPrefix += this.appConfig.mc_config.suffix;
    }
    if (
      this.utils.context.value &&
      this.utils.context.value.filter &&
      this.utils.context.value.filter.bucketFolderName
    ) {
      urlPrefix += `/${this.utils.context.value.filter.bucketFolderName}`;
    }
    return urlPrefix;
  }

  private removeFileFromList(fileToUpload: FileToUpload) {
    const fileIdx = this.files.indexOf(fileToUpload);
    if (fileIdx !== -1) {
        this.files.splice(fileIdx, 1);
    }
  }
}
