import { UserDetailModel, ConfigurationProjectModel, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import * as NavigationMainSidebarBackoffice from './navigation-main-sidebar-backoffice.constant';
import * as NavigationMainSidebarB2b from './navigation-main-sidebar-b2b.constant';
import * as NavigationMainSidebarAgent from './navigation-main-sidebar-agent.constant';
import * as NavigationMainSidebarCrm from './navigation-main-sidebar-crm.constant';
import * as NavigationMainSidebarPortal from './navigation-main-sidebar-portal.constant';

export const overrideMainSidebarLabelMap = {
    insert_key: 'insert_child_key',
};

export const excludeSidebarSections = ['insert_key']

export const overrideMainSidebarLabelMapChild = {
    insert_parent_key: {
        insert_child_key: 'insert_child_key'
    }
};

export const mainSidebarContextApplicationMap = (
    user: UserDetailModel
): ConfigurationProjectModel.Navigation.MainSidebarItemModel[] => {
    let navigationList: ConfigurationProjectModel.Navigation.MainSidebarItemModel[];
    switch (user.current_permission.context_application) {
        case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
            navigationList = NavigationMainSidebarBackoffice.list;
            break;
        case ContextApplicationItemCodeEnum.BACKOFFICE:
            navigationList = NavigationMainSidebarBackoffice.list;
            break;
        case ContextApplicationItemCodeEnum.AGENT:
            navigationList = NavigationMainSidebarAgent.list;
            break;
        case ContextApplicationItemCodeEnum.B2B:
            navigationList = NavigationMainSidebarB2b.returnList(user);
            break;
        case ContextApplicationItemCodeEnum.PORTAL:
            navigationList = NavigationMainSidebarPortal.returnList(user);
            break;
        case ContextApplicationItemCodeEnum.CRM:
            navigationList = NavigationMainSidebarCrm.list;
            break;
        default:
            navigationList = [];
    }
    const navigationList2 = [...navigationList];
    navigationList.forEach((item, i) => {
        if (Object.keys(overrideMainSidebarLabelMap).includes(item.key)) {
            item.name = overrideMainSidebarLabelMap[item.key];

        }
        // if (item.parentKey && Object.keys(excludeSidebarSubsections).includes(item.parentKey) &&
        // 	excludeSidebarSubsections[item.parentKey].includes(item.key)
        // ) {
        // 	if(Object.keys(excludeSidebarSubsections).length > 1){
        // 		if(excludeSidebarSubsections.insert_parent_key.includes(item.key))
        // 		{
        // 			navigationList.splice(i,1)
        // 		}
        // 		if(excludeSidebarSubsections.insert_parent_key1.includes(item.key))
        // 		{
        // 			excludeSidebarSubsections.insert_parent_key1.map((item1,index) =>{
        // 				if(item1 == item.key)
        // 				{
        // 					// indice riferito a navigationList.forEach
        // 					navigationList.splice(i,1)
        // 				}
        // 			})
        // 		}
        // 	}
        if(excludeSidebarSections.includes(item.key))
        {

            excludeSidebarSections.map((item1) =>{
                if(item1 === item.key)
                {
                    navigationList2.map((it, index) => {if(it.key == item1){ navigationList2.splice(index,1)}})
                }
            })
            //let new_navigationList = navigationList.slice(i);
            navigationList.forEach((item) => {
                if(item.parentKey && Object.keys(overrideMainSidebarLabelMapChild).includes(item.parentKey)
                && Object.keys(overrideMainSidebarLabelMapChild[item.parentKey]).includes(item.key))
                {
                    item.name = String(Object.values(overrideMainSidebarLabelMapChild[item.parentKey]));
                }
            })
        }
        if(item.parentKey && Object.keys(overrideMainSidebarLabelMapChild).includes(item.parentKey)
            && Object.keys(overrideMainSidebarLabelMapChild[item.parentKey]).includes(item.key))
            {
                for(const value in Object.values(overrideMainSidebarLabelMapChild[item.parentKey])){
                    if(Object.keys(overrideMainSidebarLabelMapChild[item.parentKey])[value].includes(item.key)){
                    item.name =  String(Object.values(overrideMainSidebarLabelMapChild[item.parentKey])[value]);
                    }
                }
            }

    } );
    navigationList = [...navigationList2];
    return navigationList;
};