import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import { ArticlePouchModel } from "@saep-ict/pouch_agent_models";
import _ from "lodash";

export const creationFieldMapRoot: FormControlMultipurposeModel.Item[] = [
	{
        name: 'size',
		on_form_template: true,
		label: 'article.field.size',
		type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 3
	},
    {
        name: 'is_highlighted',
        on_form_template: true,
        label: 'article.field.is_highlighted',
        type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
        column_width: 3
    },
    {
        name: 'visible',
        on_form_template: true,
        label: 'article.field.visible',
        type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
        column_width: 3
    },
    {
        name: 'stock',
        on_form_template: true,
        label: 'article.field.is_available',
        type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
        form_control_list: [
            {
                name: 'is_available',
                on_form_template: true,
                label: 'article.field.is_available',
                type: FormControlMultipurposeEnum.ItemType.BOOLEAN
            }
        ]
    },
];

export const returnConfigurationCustomerArticleDescriptionCreationFieldMap = (): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const creationFieldMapToReturn = _.cloneDeep(creationFieldMapRoot);
			resolve(creationFieldMapToReturn);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerArticleDescriptionFormValue = (article: ArticlePouchModel): Promise<any> => {
	return new Promise(resolve => {
		try {
			article = _.cloneDeep(article);
            if (article && article.articleDescription) {
                resolve({
                    size: article.articleDescription.size,
                    is_highlighted: article.articleDescription.is_highlighted,
                    stock: {
                        is_available:
                            (
                                article.articleDescription.stock &&
                                article.articleDescription.stock.is_available
                            ) ? true : false
                    },
                    visible: article.articleDescription.visible
                });
            } else {
                resolve(null);
            }
		} catch(e) {
			throw new Error(e);
		}
	});
}