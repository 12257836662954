import { Routes } from '@angular/router';
import { DataConfigurationComponent } from '../page/backoffice/data-configuration/data-configuration.component';
import { MediaCenterComponent } from '../page/commons/media-center/media-center.component';
import { UserManagementComponent } from '../page/backoffice/user-management/user-management.component';
import { UserManagementDetailComponent } from '../page/backoffice/user-management/user-management-detail/user-management-detail.component';
import { ContextCodeManagementComponent } from '../page/backoffice/context-code-management/context-code-management.component';
import { ContextCodeManagementDetailComponent } from '../page/backoffice/context-code-management/context-code-management-detail/context-code-management-detail.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { OrderComponent } from '../page/commons/order/order.component';
import { OrderDetailCatalogComponent } from '../page/commons/order/order-detail/main/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from '../page/commons/order/order-detail/main/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailVariationComponent } from '../page/commons/order/order-detail/order-detail-variation/order-detail-variation.component';
import { OrganizationComponent } from '../page/commons/organization/organization.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { FramePageComponent } from '../frame/admin/admin.component';
import { TicketCenterWrapperComponent } from '../page/commons/ticket-center-wrapper/ticket-center.component';
import { TicketCenterRoutes } from '@saep-ict/ticket-center';
import { ArticleComponent } from '../page/commons/article/article.component';
import { DashboardBackofficeComponent } from '../page/backoffice/dashboard/dashboard-backoffice/dashboard-backoffice.component';
import { DashboardBackofficeOrdersComponent } from '../page/backoffice/dashboard/dashboard-backoffice-orders/dashboard-backoffice-orders.component';
import { DashboardBackofficeClientsComponent } from '../page/backoffice/dashboard/dashboard-backoffice-clients/dashboard-backoffice-clients.component';
import { DashboardBackofficeSoldComponent } from '../page/backoffice/dashboard/dashboard-backoffice-sold/dashboard-backoffice-sold.component';
import { DashboardBackofficeExpiredComponent } from '../page/backoffice/dashboard/dashboard-backoffice-expired/dashboard-backoffice-expired.component';
import { OrganizationDetailOverviewComponent } from '../page/commons/organization/organization-detail/organization-detail-overview/organization-detail-overview.component';
import { OrganizationDetailCreditComponent } from '../page/commons/organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailCommentComponent } from '../page/commons/organization/organization-detail/organization-detail-comment/organization-detail-comment.component';
import { ArticleDetailComponent } from '../page/commons/article/article-detail/article-detail.component';
import { RequestListComponent } from '../page/commons/request-list/request-list.component';
import { InformativePageListComponent } from '../page/backoffice/informative-page/informative-page-list/informative-page-list.component';
import { InformativePageDetailComponent } from '../page/backoffice/informative-page/informative-page-detail/informative-page-detail.component';
import { PermissionKeyEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { SurveyCenterComponent } from '../page/commons/survey-center/survey-center.component';
import { AssetRequestCenterComponent } from '../page/commons/asset-request-center/asset-request-center.component';
import { BackofficeForecastComponent } from '../page/backoffice/backoffice-forecast/backoffice-forecast.component';
import { BackofficeReportsComponent } from '../page/backoffice/backoffice-reports/backoffice-reports.component';
import { NewsComponent } from '../page/commons/news/news.component';
import { NewsListWrapperComponent } from '../widget/news/news-list-wrapper/news-list-wrapper.component';
import { ProductStockComponent } from '../page/backoffice/product-stock/product-stock.component';
import { RequestListWrapperComponent } from '../widget/request/request-list-wrapper/request-list-wrapper.component';
import { RequestDetailComponent } from '../page/commons/request-list/request-detail/request-detail.component';
import { NewsDetailComponent } from '../page/commons/news/news-detail/news-detail.component';
import { CategoryListComponent } from '../page/backoffice/category-list/category-list.component';
import { OrderDetailComponent } from '../page/commons/order/order-detail/order-detail.component';
import { OrderRevisionDetailComponent } from '../page/commons/order/order-revision-detail/order-revision-detail.component';
import { RichTextEditorComponent } from '../page/backoffice/rich-text-editor/rich-text-editor.component';
import { FamilyComponent } from '../page/commons/family/family.component';
import { FamilyDetailComponent } from '../page/commons/family/family-detail/family-detail.component';
import { ReceiptComponent } from '../page/commons/receipt/receipt.component';
import { AdditionalServiceConfigurationComponent } from '../page/backoffice/additional-service-configuration/additional-service-configuration.component';
import { TranslateBundleResolver } from '../service/resolvers/translate-bundle.resolver';



export const routes: Routes = [
	{
		path: '',
		component: FramePageComponent,
		children: [
			// TODO: rimuovere dopo la scelta di un editor e la sua integrazione definitiva
			{
				path: 'rich-text-editor',
				component: RichTextEditorComponent,
				data: { permissionKey: ['rich-text-editor'] }
			},		
			// Dashboard
			{
				path: '',
				component: DashboardBackofficeComponent,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD] },
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.dashboardOrders,
				component: DashboardBackofficeOrdersComponent,
				data: {
					permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
				}
			},
			{
				path: ROUTE_URL.dashboardClients,
				component: DashboardBackofficeClientsComponent,
				data: {
					permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
				}
			},
			{
				path: ROUTE_URL.dashboardSold,
				component: DashboardBackofficeSoldComponent,
				data: {
					permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
				}
			},
			{
				path: ROUTE_URL.dashboardExpired,
				component: DashboardBackofficeExpiredComponent,
				data: {
					permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
				}
			},
			// user-management
			{
				path: ROUTE_URL.userManagement,
				children: [
					{
						path: '',
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_USER_MANAGEMENT_LIST] },
						component: UserManagementComponent,
						pathMatch: 'full'
					},
					{
						path: ':idUser',
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_USER_MANAGEMENT_LIST] },
						component: UserManagementDetailComponent,
						pathMatch: 'full'
					}
				]
			},
			// configuration
			{
				path: ROUTE_URL.configurations,
				component: DataConfigurationComponent,
				data: {
					permissionKey: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION]
				},
				pathMatch: 'full'
			},
			// context code
			{
				path: `${ROUTE_URL.contextCodeManagement}/:contextApplicationType`,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST] },
				children: [
					{
						path: '',
						component: ContextCodeManagementComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST] },
						pathMatch: 'full'
					},
					{
						path: ':contextCode',
						component: ContextCodeManagementDetailComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_DETAIL] },
						pathMatch: 'full'
					}
				]
			},
			// Mediacenter
			{
				path: `${ROUTE_URL.mediaCenter}/:bucketFolderName`,
				resolve: {
					translateBundle: TranslateBundleResolver
				},
				data: {
					translateBundlePath: './assets/media-center/assets/i18n',
					permissionKey: [
						PermissionKeyEnum.BACKOFFICE_MEDIACENTER,
						'backoffice-mediacenter-italy'
					]
				},
				component: MediaCenterComponent,
				pathMatch: 'full'
			},
			// orders
			// {
			// 	path: ROUTE_URL.orders,
			// 	data: {
			// 		permissionKey: [
			// 			PermissionKeyEnum.BACKOFFICE_ORDER_LIST,
			// 			PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL
			// 		]
			// 	},
			// 	children: [
			// 		{
			// 			path: ':orderStatus',
			// 			component: OrderComponent,
			// 			data: {
			// 				permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_LIST],
			// 				meta: {
			// 					title: 'Lista ordini',
			// 					description: 'Lista degli ordini divisi per stato'
			// 				}
			// 			},
			// 			resolve: {
			// 				meta: MetaDataResolver
			// 			},
			// 			children: []
			// 		},
			// 		{
			// 			path: ':orderStatus/:organizationId/:orderId',
			// 			//component: OrderDetailComponent,
			// 			component: OrderDetailComponent,
			// 			data: {
			// 				permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL],
			// 				meta: {
			// 					title: 'Dettaglio ordine',
			// 					description: "Dettaglio testata e prodotti dell'ordine"
			// 				}
			// 			},
			// 			resolve: {
			// 				meta: MetaDataResolver
			// 			},
			// 			children: [
			// 				{
			// 					path: '',
			// 					redirectTo: ROUTE_URL.catalog,
			// 					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] },
			// 					pathMatch: 'full'
			// 				},
			// 				{
			// 					path: ROUTE_URL.catalog,
			// 					component: OrderDetailCatalogComponent,
			// 					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
			// 				},
			// 				{
			// 					path: ROUTE_URL.checkout,
			// 					component: OrderDetailCheckoutComponent,
			// 					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
			// 				},
			// 				{
			// 					path: ROUTE_URL.variation,
			// 					component: OrderDetailVariationComponent,
			// 					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
			// 				}
			// 			]
			// 		}
			// 	]
			// },
			// backoffice revision order 
			{
				path: ROUTE_URL.orders,
				data: {
					permissionKey: [
						PermissionKeyEnum.BACKOFFICE_ORDER_LIST,
						PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL
					]
				},
				children: [
					{
						path: ':orderStatus',
						component: OrderComponent,
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_LIST],
							meta: {
								title: 'Lista ordini',
								description: 'Lista degli ordini divisi per stato'
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						children: []
					},
					{
						path: ':orderStatus/:organizationId/:orderId',
						component: OrderRevisionDetailComponent,
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL],
							meta: {
								title: 'Dettaglio revisione ordine',
								description: "Dettaglio testata editabile e prodotti dell'ordine"
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						children: [
							{
								path: '',
								redirectTo: ROUTE_URL.catalog,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.catalog,
								component: OrderDetailCatalogComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
							},
							{
								path: ROUTE_URL.checkout,
								component: OrderDetailCheckoutComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
							},
							{
								path: ROUTE_URL.variation,
								component: OrderDetailVariationComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
							}
						]
					}
				]
			},				

			// organizations
			{
				path: `${ROUTE_URL.organizations}/:organizationLevel`,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST] },
				children: [
					{
						path: '',
						component: OrganizationComponent,
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST],
							meta: {
								title: 'Lista organizzazioni',
								description: 'Lista delle organizzazioni filtrate per tipo'
							}
						},
						resolve: {
							meta: MetaDataResolver
						}
					},
					{
						path: ':idOrganization',
						component: OrganizationDetailComponent,
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_DETAIL],
							meta: {
								title: 'Dettaglio azienda',
								description: "Sezione di dettaglio dell'azienda"
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						children: [
							{
								path: '',
								redirectTo: ROUTE_URL.overview,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_DASHBOARD] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.overview,
								component: OrganizationDetailOverviewComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_DASHBOARD] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.details,
								component: OrganizationDetailFullDetailComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_FULL_DETAIL] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.orders,
								component: OrderComponent,
								data: {
									refreshChild: true,
									permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_LIST]
								},
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.contacts,
								component: ContactComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_CONTACT] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.addresses,
								component: OrganizationDetailDestinationComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_DESTINATION] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.credits,
								component: OrganizationDetailCreditComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_CREDIT] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.comments,
								component: OrganizationDetailCommentComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_COMMENT] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.receipts,
								component: ReceiptComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_INVOICE_LIST] },
								pathMatch: 'full'
							}
						]
					}
				]
			},
			// contacts
			{
				path: `${ROUTE_URL.contacts}`,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTACT_LIST] },
				children: [
					{
						path: '',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTACT_LIST],
							meta: {
								title: 'Contatti backoffice',
								description: 'Lista dei contatti associati al backofficer'
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						component: ContactComponent
					}
				]
			},
			// ticket-center
			{
				path: `${ROUTE_URL.ticketCenter}`,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_TICKET_CENTER] },
				children: [
					{
						path: '',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_TICKET_CENTER],
							meta: {
								title: 'Ticket Center',
								description: 'Ticket Center'
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						component: TicketCenterWrapperComponent,
						children: TicketCenterRoutes
					}
				]
			},
			// catalog
			{
				path: `${ROUTE_URL.catalog}`,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_LIST] },
				children: [
					{
						path: ROUTE_URL.categories,
						component: CategoryListComponent,
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_CATEGORY_CONFIGURATION_LIST]
						},
						pathMatch: 'full'
					},
					{
						path: ROUTE_URL.articles,
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_LIST],
							instanceType: 'article'
						},
						children: [
							{
								path: '',
								component: ArticleComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_LIST] },
								pathMatch: 'full'
							},
							{
								path: `:articleId`,
								component: ArticleDetailComponent,
								data: {
									permissionKey: [PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_DETAIL]
								},
								pathMatch: 'full'
							}
						]
					},
					{
						path: ROUTE_URL.families,
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_FAMILY_CONFIGURATION_LIST],
							instanceType: 'family'
						},
						children: [
							{
								path: '',
								component: FamilyComponent,
								data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_LIST] },
								pathMatch: 'full'
							},
							{
								path: `:familyId`,
								component: FamilyDetailComponent,
								data: {
									permissionKey: [PermissionKeyEnum.BACKOFFICE_ARTICLE_CONFIGURATION_DETAIL]
								},
								pathMatch: 'full'
							}
						]
					}
				]
			},
			// Requests
			{
				path: ROUTE_URL.requests,
				component: RequestListComponent,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_REGISTRATION_REQUEST_LIST] },
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: ROUTE_URL.allRequests,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_REGISTRATION_REQUEST_LIST] }
					},						
					{
						path: ROUTE_URL.openRequests,
						component: RequestListWrapperComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_REGISTRATION_REQUEST_LIST] }
					},
					{
						path: ROUTE_URL.closedRequests,
						component: RequestListWrapperComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_REGISTRATION_REQUEST_LIST] }
					},
					{
						path: ROUTE_URL.allRequests,
						component: RequestListWrapperComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_REGISTRATION_REQUEST_LIST] }
					}
				]
			},
			// Request detail
			{
				path: `${ROUTE_URL.requests}/:idRequest`,
				component: RequestDetailComponent,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_REGISTRATION_REQUEST_DETAIL] }
			},
			// informative-page
			{
				path: `${ROUTE_URL.informativePage}`,
				data: {
					permissionKey: [PermissionKeyEnum.BACKOFFICE_INFORMATIVE_PAGE_LIST]
				},
				children: [
					{
						path: '',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_INFORMATIVE_PAGE_LIST]
						},
						component: InformativePageListComponent
					},
					{
						path: ':informativePageType',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_INFORMATIVE_PAGE_DETAIL]
						},
						component: InformativePageDetailComponent
					}
				]
			},
			// News
			{
				path: ROUTE_URL.news,
				component: NewsComponent,
				data: {
					permissionKey: [PermissionKeyEnum.BACKOFFICE_NEWS],
					meta: {
						title: 'News'
					}
				},
				resolve: {
					meta: MetaDataResolver
				},
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: ROUTE_URL.allNews,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_NEWS] }
					},
					{
						path: ROUTE_URL.new,
						component: NewsListWrapperComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_NEWS] }
					},
					{
						path: ROUTE_URL.archive,
						component: NewsListWrapperComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_NEWS] }
					},
					{
						path: ROUTE_URL.allNews,
						component: NewsListWrapperComponent,
						data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_NEWS] }
					}
				]
			},
			{
				path: `${ROUTE_URL.news}/:idNews`,
				component: NewsDetailComponent,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_NEWS] }
			},
			// Survey Center
				{
					path: ROUTE_URL.budget,
					data: {
						permissionKey: [PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_BUDGET],
						pageKey: ROUTE_URL.budget
					},
					component: SurveyCenterComponent
				},
				{
					path: ROUTE_URL.mediaMarketing,
					data: {
						permissionKey: [PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_MEDIA_MARKETING],
						pageKey: ROUTE_URL.mediaMarketing
					},
					component: SurveyCenterComponent
				},
			// Asset request center
			{
				path: ROUTE_URL.assetRequestTicketList,
				data: {
					permissionKey: [
						PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER,
						PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER,
						PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER,
						PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
					]
				},
				children: [
					{
						path: 'image-video/euroitalia',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER],
							assetRequestType: 'image-video',
							userType: 'euroitalia'
						},
						component: AssetRequestCenterComponent
					},
					{
						path: 'image-video/agency',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER],
							assetRequestType: 'image-video',
							userType: 'agency'
						},
						component: AssetRequestCenterComponent
					},
					{
						path: 'print-production-flow/euroitalia',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER],
							assetRequestType: 'print-production-flow',
							userType: 'euroitalia'
						},
						component: AssetRequestCenterComponent
					},
					{
						path: 'print-production-flow/agency',
						data: {
							permissionKey: [PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER],
							userType: 'agency',
							assetRequestType: 'print-production-flow',
						},
						component: AssetRequestCenterComponent
					},
				]
			},
			// Reports
			{
				path: ROUTE_URL.reports,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD] },
				component: BackofficeReportsComponent,
				pathMatch: 'full'
			},
			// Product stock
			{
				path: ROUTE_URL.productStock,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD] },
				component: ProductStockComponent,
				pathMatch: 'full'
			},
			// Fatture
			{
				path: `${ROUTE_URL.receipts}`,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_INVOICE_LIST] }, 
				component: ReceiptComponent
			},
			{
				path: ROUTE_URL.additionalServiceConfiguration,
				data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ADDITIONAL_SERVICE_CONFIGURATION] },
				component: AdditionalServiceConfigurationComponent,
				pathMatch: 'full'
			}
		]
	}
];
