<input
	#fileInputContainer
	type="file"
	[multiple]="multiple"
	[accept]="acceptedType"
	hidden
	(change)="onFilesFromSelectChange($event)"
/>
<div drop-area (filesChangeEmiter)="onFilesChange($event)" class="drop-area-container">
	<p>
		<mat-icon>cloud_upload</mat-icon>
		<span>{{ 'saep_ict_angular_core.upload.drang_and_drop_area.start' | translate | sentencecase }}
		<a href="javascript:void(0)" (click)="eventWrapperInput()">
			{{ 'saep_ict_angular_core.upload.drang_and_drop_area.end_with_link' | translate }}
		</a>
  </span>
	</p>
</div>
