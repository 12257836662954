import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import {
	OrganizationListFilterCreationFieldMapFormValue,
	OrganizationListFilterCreationFieldMapSelectOptionMap
} from '../../../../../model/organization.model';
import {
	returnConfigurationCustomerOrganizationListFilterByFormFieldStatus,
	returnCreationFieldMapWithOptionOrganizationStatus,
} from './organization-list-filtercreation-field-map.util.constant';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
	{
		name: 'status',
		on_form_template: true,
		label: 'organization.field.status',
		type: FormControlMultipurposeEnum.ItemType.SELECT
	}
];

export const returnConfigurationCustomerOrganizationListFilterCreationFieldMapDefault = async (
	selectOptionMap: OrganizationListFilterCreationFieldMapSelectOptionMap
): Promise<FormControlMultipurposeModel.Item[]> => {
	try {
		let creationFieldMapToReturn = _.cloneDeep(creationFieldMap);
		creationFieldMapToReturn = await returnCreationFieldMapWithOptionOrganizationStatus(
			creationFieldMapToReturn,
			selectOptionMap
		);
		return creationFieldMapToReturn;
	} catch (e) {
		throw new Error(e);
	}
};

export const returnConfigurationCustomerOrganizationListFilterByFormValueDefault = async (
	organizationList: OrganizationPouchModel[],
	formValue: OrganizationListFilterCreationFieldMapFormValue
): Promise<OrganizationPouchModel[]> => {
	try {
		if (organizationList && organizationList.length && formValue) {
			organizationList = await returnConfigurationCustomerOrganizationListFilterByFormFieldStatus(
				organizationList,
				formValue
			);
		}
		return organizationList;
	} catch (err) {
		throw new Error(err);
	}
};
