import { PaymentIconItem } from '../../../model/payment.model';

export const iconList: PaymentIconItem[] = [
	{
		description: 'footer.field.payment.mastercard',
		icon: '/assets/common/img/metodi-pagamento/cc-mastercard.svg'
	},
	{
		description: 'footer.field.payment.visa',
		icon: '/assets/common/img/metodi-pagamento/cc-visa.svg'
	},
	{
		description: 'footer.field.payment.sepa',
		icon: '/assets/common/img/metodi-pagamento/cc-sepa.svg'
	}
];
