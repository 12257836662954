import {
	ConfigurationProjectModel,
	ConfigurationUtil
} from '@saep-ict/angular-spin8-core';
import { UserManagementStateAction } from '../../../state/backoffice/user-management/user-management.actions';

export const canEditContextCode: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: true,
    B2C: true,
    BACKOFFICE_ADMIN: true,
    BACKOFFICE: true,
    PORTAL: true,
    CRM: true,
    PUBLIC: false
};
export const storeAction: ConfigurationProjectModel.StoreAction = {
    load_list: UserManagementStateAction.loadList,
    load_detail: UserManagementStateAction.loadDetail,
    reset: UserManagementStateAction.reset
};