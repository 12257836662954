import { FormControlMultipurposeModel } from '@saep-ict/angular-core';
import {
	OrganizationListFilterCreationFieldMapFormValue,
	OrganizationListFilterCreationFieldMapSelectOptionMap
} from '../../../../../model/organization.model';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

// Add options - status
export const returnCreationFieldMapWithOptionOrganizationStatus = (
	creationFieldMap: FormControlMultipurposeModel.Item[],
	selectOptionMap: OrganizationListFilterCreationFieldMapSelectOptionMap
): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const organizationStatusField = creationFieldMap.find(i => i.name === 'status');
			organizationStatusField.option_list = selectOptionMap.status.map(i => {
				return {
					value: i,
					label: i
				};
			});
			resolve(creationFieldMap);
		} catch (e) {
			throw new Error(e);
		}
	});
};

// Filter - status
export const returnConfigurationCustomerOrganizationListFilterByFormFieldStatus = (
	organizationList: OrganizationPouchModel[],
	formValue: OrganizationListFilterCreationFieldMapFormValue
): Promise<OrganizationPouchModel[]> => {
	return new Promise(resolve => {
		try {
			if (organizationList && organizationList.length && formValue) {
				if (formValue.status) {
					const filteredOrganizationStatusList = [];
					for (const organization of organizationList) {
						if (organization.status === formValue.status) {
							filteredOrganizationStatusList.unshift(organization);
						}
					}
					organizationList = filteredOrganizationStatusList;
				}
			}
			resolve(organizationList);
		} catch (e) {
			throw new Error(e);
		}
	});
};