<page-with-sidebar
	[ngClass]="{'agent-catalog': user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT}"
	[sideBarPosition]="
		configurationCustomerOrder.commonCatalogCategoryStructure ===
		orderCommonCatalogEnum.CategoryStructure.NESTED_IN_SIDEBAR
			? sideBarPositionValues.RIGHT
			: sideBarPositionValues.NONE
	"
>
	<ng-container pageMain >
		<ng-container *ngIf="orderCommonCatalogEnum.CategoryStructure.TAB_ON_TOP">
			<category-tab-list
				[list]="categoryListParseVisualization"
				[configuration]="configurationCustomerOrder.commonCatalogCategoryTabOnTopConfiguration"
				(categorySelectedChange)="categorySelectedChange($event)"
			></category-tab-list>
		</ng-container>
		<ng-container *ngTemplateOutlet="articleStructure"></ng-container>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper>
			<ng-container main-content>
				<ng-container
					*ngIf="
						configurationCustomerOrder.commonCatalogCategoryStructure ===
						orderCommonCatalogEnum.CategoryStructure.NESTED_IN_SIDEBAR
					"
				>
					<h3>{{ 'general.category' | translate | sentencecase }}</h3>
					<nested-category-list
						[list]="categoryListParseVisualization"
						[debounceTime]="700"
						(categorySelectedChange)="categorySelectedChange($event)"
					>
					</nested-category-list>
				</ng-container>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>

<!-- strutture lista articoli gestite -->

<ng-template #articleStructure>
	<span #scrollbarRef></span>
	<ng-container *ngIf="listPageBaseData" [ngSwitch]="articleStructureKey">
		<article-list-structure-wrapper-category-tree
			*ngSwitchCase="orderEnum.CommonArticleStructure.CATEGORY_TREE"
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="true"
			[localListHandlerSidebarFilterShow]="isMobile"
			[localListHandlerTitle]="'article.list' | translate | sentencecase"
			[organization]="organization"
			[configurationAction]="articleTableConfigurationAction"
			(onArticleChange)="onArticleChangeHandler($event)"
			(localListHandlerOpenSidebarFilter)="listWrapper.sidenav.open()"
			class="catalog-article-table-wrapper"
		>
		</article-list-structure-wrapper-category-tree>
		<article-list-structure-wrapper-plain-list
			*ngSwitchCase="orderEnum.CommonArticleStructure.PLAIN_LIST"
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="true"
			[localListHandlerSidebarFilterShow]="isMobile"
			[organization]="organization"
			[order]="orderForArticleStructure"
			[paginatorOnTopShow]="true"
			[configurationAction]="articleTableConfigurationAction"
			[virtualScrollConfiguration]="configurationCustomerOrder.commonCatalogVirtualScrollConfiguration"
			(onArticleChange)="onArticleChangeHandler($event)"
			(localListHandlerOpenSidebarFilter)="listWrapper.sidenav.open()"
			class="catalog-article-table-wrapper"
		>
		</article-list-structure-wrapper-plain-list>
		<!-- TODO: sostituire con enum -->
		<article-list-structure-wrapper-accordion
			*ngSwitchCase="orderEnum.CommonArticleStructure.ACCORDION"
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerSidebarFilterShow]="isMobile"
			[localListHandlerTitle]="'article.list' | translate | sentencecase"
			[organization]="organization"
			[configurationAction]="articleTableConfigurationAction"
			[order]="orderForArticleStructure"
			[configuration]="
				{
					multipleOpen: configurationCustomerOrder.commonCatalogArticleStructureAccordionMultipleOpen,
					section: configurationCustomerOrder.categorySectionList
				}
			"
			(onArticleChange)="onArticleChangeHandler($event)"
			class="catalog-article-table-wrapper"
		>
		</article-list-structure-wrapper-accordion>
	</ng-container>
</ng-template>
