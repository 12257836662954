import { ListAttribute } from './misc.model';

export interface MediaCenterModel {
	id?: string;
	name?: string;
	path?: string;
	date_update?: string;
	fileType?: string;
	size?: number;
	actions?: string[];
	isSystem?: boolean;
	bucket_link_thumbnail?: string;
}

export class MediaCenterListModel extends ListAttribute {
	list?: MediaCenterModel[];
}

export interface MediaCenterResponseModel {
  data: MediaCenterFolderContent;
}

export interface MediaCenterFolderContent {
	id?: string;
	currentFolder?: MediaCenterModel;
	files?: MediaCenterModel[];
	folders?: MediaCenterModel[];
}

export interface MoveItemListPayload {
	pathList: string[];
	pathNew: string;
}

export interface MediaCenterContext {
	filter?: MediaCenterFilter;
	customHeaders: any;
}

export interface MediaCenterFilter {
	text?: string;
  latest?: boolean;
  limit?: number;
  path?: string;
  bucketFolderName?: string;
  actionDownloadOnly?: boolean;
  actionDownloadArchive?: boolean;
  avoidItemSelection?: boolean;
  /**
   * Consente al loader istanziato dal media center di espandersi fino al primo container con
   * adeguato `position` (essendo il loader `position: absolute`)
   */
  loaderExpandBeyondLibrary?: boolean;
}
