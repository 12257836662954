import { BasePouchModel, BodyTablePouchModel } from './base-pouch.model';
import { ConfigurationInput } from './forecast.model';
import { DivisionPouchModel } from './organization-pouch.model';
import { DiscountModel, LanguageItem } from './util-pouch.model';

export interface ArticlePouchModel<T = never> extends BodyTablePouchModel, BasePouchModel {
	custom_field?: T;
	// ordine
	order_causal_code?: string;
	input_quantity?: number;
	ordered_quantity?: number;
	qty_free?: number;
	note_order?: string;
	delivery_date?: number;
	qty_shipped?: number;
	calculate_price?: number;
	calculate_price_for_single_item?: number;
	articlePrice?: ArticlePouchPriceModel;
	forecast?: ConfigurationInput[]

	// anagrafica cliente/default (*/article_recap_*)
	discount_agent?: DiscountModel;
	discount?: DiscountModel[];
	price?: number;
	vat?: number;
	division_list?: DivisionPouchModel[];

	// anagrafica generale (commons/article_recap_description)
	articleDescription?: ArticleDescription;

	// table utility
	tableMetaInformation?: ArticleTableMetaInformation;
}

export interface ArticlePouchPriceModel {
	price: number;
	discount?: DiscountModel[];
	vat?: number;
}

export interface ArticleDescriptionItem extends ArticleDescription, BodyTablePouchModel {}

export interface ArticleDescription<T = never> {
	aggregated_info?: any;
	art_kit?: string;
	badge_list?: string[],
	category_list: string[];
	cf_available?: string;
	conversion?: boolean;
	free_sample?: string;
	is_highlighted: boolean;
	is_tester?: boolean;
	language_list: LanguageItem[];
	qty_box?: number;
	related_article_list: string[];
	related_kit_list: ArticlePouchModel<T>[];
	related_tester?: string;
	relatedArticleTester?: ArticlePouchModel;
	sequence?: number;
	size?: string;
	stock?: ArticleStockPouchModel;
	um_selling?: string;
	um_warehouse?: string;
	valid?: boolean;
	visible?: boolean;
	weight?: number;
	origin?: BodyTablePouchModel;
}

export interface ArticleStockPouchModel {
	code_company: string;
	code_segnalation: string;
	code_warehouse: string;
	code: string;
	description: {
		article: string;
		line: string;
		brand: string;
	}
	description_segnalation: string;
	description_warehouse: string;
	is_available?: boolean;
	percentage_availability: number;
	qty_available: number;
	qty_commited: number;
	qty_current: number;
	qty_incoming: number;
}

export interface ArticleTableMetaInformation {
	discountDescription: string;
	priceWithDiscountDescription: string;
	inputQuantityDisable: boolean;
	hasGalleryImage?: boolean;
}
