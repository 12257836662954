import { Component, EventEmitter, OnInit, Output, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AttachmentResolverService } from '../../service/structure/attachment-resolver.service';
import { UploadService } from '../../service/upload/upload.service';

export interface PreviewFile extends File {
	preview?: string | ArrayBuffer;
}

@Component({
	selector: 'attachment-resolver',
	templateUrl: './attachment-resolver.component.html',
	styleUrls: ['./attachment-resolver.component.scss']
})
export class AttachmentResolverComponent implements OnInit, OnDestroy {
	@Input() dropAreaEnabled: boolean = true;
	@Input() multiple: boolean;
	@Input() acceptedType: string;
	@Input() fileEmitterType: 'files' | 'filesPreview' = 'filesPreview';
	@Output() fileEmitter: EventEmitter<any> = new EventEmitter();

	files: PreviewFile[] = [];
	filesPreview = [];
	emitCheck = false;
	private empty: Subscription;

	constructor(private attachmentResolverService: AttachmentResolverService, public uploadService: UploadService) {
		this.empty = this.attachmentResolverService.emptyAR$.subscribe(res => {
			this.files = [];
			this.filesPreview = [];
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.empty.unsubscribe();
	}

	changeFiles(files: FileList) {
		const filesArray = Array.from(files);
		if (!this.multiple) {
			this.files = [];
			this.filesPreview = [];
		}
		filesArray.forEach((file: File, i) => {
			this.files.push(file);
			if (filesArray.length === i + 1) {
				// se true scatena l'emit in readThisPreview()
				this.emitCheck = true;
			}
			this.readThisPreview(file);
		});
	}

	readThisPreview(inputValue: PreviewFile): void {
		const myReader: FileReader = new FileReader();
		myReader.readAsDataURL(inputValue);
		myReader.onload = _event => {
			// genera la preview di un file type image/*
			if (inputValue.type.includes('image/')) {
				inputValue.preview = myReader.result;
			}
			this.filesPreview.push({
				data: myReader.result,
				name: inputValue.name
			});
			if (this.emitCheck) {
				this.emitFile();
				this.emitCheck = false;
			}
		};
	}

	deleteFile(index: number) {
		this.uploadService.cancelUpload(index);
		this.files.splice(index, 1);
		this.filesPreview.splice(index, 1);
		this.emitFile();
	}

	emitFile() {
		this.fileEmitter.emit(this[this.fileEmitterType]);
	}
}
