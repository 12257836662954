import {
	ContextApplicationItemCodeEnum,
	ConfigurationProjectModel,
	ConfigurationUtil,
    ITdDataTableColumnCustom,
    OrganizationStateModel,
    ArticleRecap,
} from '@saep-ict/angular-spin8-core';
import { BaseStateModel, FormControlMultipurposeModel }  from '@saep-ict/angular-core';
import * as columnListOrganizationBackoffice from './organization-column-map/organization-column-map-backoffice.constant';
import * as columnListOrganizationAgent from './organization-column-map/organization-column-map-agent.constant';
import * as columnListOrganizationCRM from './organization-column-map/organization-column-map-crm-constant';
import { OrganizationListFilterCreationFieldMapFormValue, OrganizationListFilterCreationFieldMapSelectOptionMap } from '../../../model/organization.model';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import {
    returnConfigurationCustomerOrganizationListFilterByFormValueDefault,
    returnConfigurationCustomerOrganizationListFilterCreationFieldMapDefault
} from './organization-list-filter/organization-list-filter-creation-field-map/organization-list-filter-creation-field-map-default.constant';
import * as ConfigurationCustomerAppStructure from '../app-structure/app-structure.constant';
import { PouchDbAgentAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-agent.adapter';
import { PouchDbBackofficeAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-backoffice.adapter';
import { PouchDbCommonsAgentAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-agent.adapter';
import { PouchDbCommonsArticleAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-article.adapter';
import { PouchDbCommonsBackofficeAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-backoffice.adapter';
import { PouchDbCommonsContactAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-contact.adapter';
import { PouchDbCommonsCrmAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-crm.adapter';
import { PouchDbCommonsOrderAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-order.adapter';
import { PouchDbCommonsOrganizationAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-organization.adapter';
import { PouchDbCommonsRequestAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-request';
import { PouchDbCommonsUserAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-commons-user.adapter';
import { PouchDbCrmAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-crm.adapter';
import { PouchDbOrganizationB2BAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-organization-b2b.adapter';
import { PouchDbOrganizationB2CAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-organization-b2c.adapter';
import { PouchDbOrganizationPortalAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-organization-portal.adapter';
import { PouchDbSpin8AgentAdapter } from '../../../../../src/app/service/pouch-db/spin8/pouchdb-spin8-agent.adapter';

export const canCreate: ConfigurationProjectModel.Organization = {
    detail: {
        AGENT: true,
        B2B: false,
        B2C: false,
        BACKOFFICE_ADMIN: false,
        BACKOFFICE: false,
        PORTAL: true,
        CRM: true,
        PUBLIC: false
    },
    destination: {
        AGENT: true,
        B2B: true,
        B2C: true,
        BACKOFFICE_ADMIN: false,
        BACKOFFICE: false,
        PORTAL: true,
        CRM: true,
        PUBLIC: false
    },
    contact: {
        AGENT: true,
        B2B: true,
        B2C: true,
        BACKOFFICE_ADMIN: false,
        BACKOFFICE: false,
        PORTAL: true,
        CRM: true,
        PUBLIC: false
    }
};
export const canEdit: ConfigurationProjectModel.Organization = {
    detail: {
        AGENT: true,
        B2B: true,
        B2C: true,
        BACKOFFICE_ADMIN: false,
        BACKOFFICE: false,
        PORTAL: true,
        CRM: true,
        PUBLIC: false
    },
    destination: {
        AGENT: true,
        B2B: false,
        B2C: true,
        BACKOFFICE_ADMIN: true,
        BACKOFFICE: false,
        PORTAL: true,
        CRM: true,
        PUBLIC: false
    },
    contact: {
        AGENT: true,
        B2B: false,
        B2C: true,
        BACKOFFICE_ADMIN: false,
        BACKOFFICE: false,
        PORTAL: true,
        CRM: true,
        PUBLIC: false
    }
};
export const displayPaymentMethod: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: true,
    B2C: false,
    BACKOFFICE_ADMIN: false,
    BACKOFFICE: true,
    PORTAL: false,
    CRM: true,
    PUBLIC: false
};
export const displayUtilLinks: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: false,
    B2B: false,
    B2C: false,
    BACKOFFICE_ADMIN: false,
    BACKOFFICE: false,
    PORTAL: false,
    CRM: true,
    PUBLIC: false
};
export const displayPersonalData: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: false,
    B2B: false,
    B2C: false,
    BACKOFFICE_ADMIN: false,
    BACKOFFICE: false,
    PORTAL: false,
    CRM: true,
    PUBLIC: false
};
export const displayLegalInformation: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: true,
    B2C: false,
    BACKOFFICE_ADMIN: true,
    BACKOFFICE: true,
    PORTAL: false,
    CRM: true,
    PUBLIC: false
}

export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumnCustom[] => {
    switch (contextApplication) {
        case(ContextApplicationItemCodeEnum.BACKOFFICE):
            return columnListOrganizationBackoffice.projectOverride;
        case(ContextApplicationItemCodeEnum.AGENT):
             return columnListOrganizationAgent.projectOverride;
        case(ContextApplicationItemCodeEnum.CRM):
            return columnListOrganizationCRM.projectOverride
    }
};

export const displayCreditDebitInfoBox: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
    AGENT: true,
    B2B: false,
    B2C: true,
    BACKOFFICE_ADMIN: true,
    BACKOFFICE: true,
    PORTAL: true,
    CRM: true
};

export const listFilterCreationFieldMap = async (
    contextApplication: ContextApplicationItemCodeEnum,
    selectOptionMap: OrganizationListFilterCreationFieldMapSelectOptionMap
): Promise<FormControlMultipurposeModel.Item[]> => {
    try {
        switch (contextApplication) {
            default:
                // TODO: determinare quale sia la mappa di default. Questo schema è ereditato da
                // filterOrderVisible
                return await returnConfigurationCustomerOrganizationListFilterCreationFieldMapDefault(selectOptionMap);
            
        }
    } catch(e) {
        throw new Error(e);
    }
};
export const returnListFilterByFormValue = async (
    contextApplication: ContextApplicationItemCodeEnum,
    organizationlist: OrganizationPouchModel[],
    formValue: OrganizationListFilterCreationFieldMapFormValue
): Promise<OrganizationPouchModel[]> => {
    try {
        switch (contextApplication) {
            default:
                return await returnConfigurationCustomerOrganizationListFilterByFormValueDefault(organizationlist, formValue);
        }
    } catch(e) {
        throw new Error(e);
    }
}

export const getOrganizationArticleRecapType = async (
    data: BaseStateModel<OrganizationStateModel>,
    pouchAdapter: PouchDbAgentAdapter
    | PouchDbSpin8AgentAdapter
    | PouchDbOrganizationB2CAdapter
    | PouchDbOrganizationB2BAdapter
    | PouchDbOrganizationPortalAdapter
    | PouchDbBackofficeAdapter
    | PouchDbCrmAdapter
    | PouchDbCommonsOrganizationAdapter
    | PouchDbCommonsArticleAdapter
    | PouchDbCommonsOrderAdapter
    | PouchDbCommonsUserAdapter
    | PouchDbCommonsAgentAdapter
    | PouchDbCommonsBackofficeAdapter
    | PouchDbCommonsContactAdapter
    | PouchDbCommonsRequestAdapter
    | PouchDbCommonsCrmAdapter
): Promise<BaseStateModel<OrganizationStateModel>> => {
    try {
        const _id = `article_recap${ConfigurationCustomerAppStructure.noSqlDocSeparator}${data.data.code_item}`;
        await pouchAdapter.basePouch.getDetail<ArticleRecap>(_id).then(() => {
            data.data.article_recap_code_item = true;
        }).catch(e => {
            if (e.status !== 404) {
                data.data.article_recap_code_item = false;
            }
        });
        return data;
    } catch (err) {
        throw new Error(err);
    }
}