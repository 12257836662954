import { ConfigurationProjectModel, UserDetailModel, ROUTE_URL, PermissionKeyEnum } from '@saep-ict/angular-spin8-core';
import _ from "lodash";

export const returnList = (
	user: UserDetailModel
): ConfigurationProjectModel.Navigation.MainSidebarItemModel[] => {
	const map: ConfigurationProjectModel.Navigation.MainSidebarItemModel[] = [
		{
			key: 'company',
			name: 'saep_ict_angular_spin8_core.page.organization.name',
			icon: 'custom-business',
			iconCustom: true,
			route: `${ROUTE_URL.company}/${user.current_permission.context_code.code}`,
			position: 2,
			permission: PermissionKeyEnum.PORTAL_DASHBOARD
		},
		{
			key: 'media-center',
			name: 'saep_ict_angular_spin8_core.page.media_center.name',
			icon: 'custom-folder-2',
			iconCustom: true,
			route: `${ROUTE_URL.mediaCenter}/mediacenter`,
			position: 5,
			permission: PermissionKeyEnum.PORTAL_MEDIACENTER
		},
		{
			key: 'ticket_center',
			name: 'saep_ict_angular_spin8_core.page.ticket_center.name',
			icon: 'custom-confirmation_number',
			iconCustom: true,
			route: `${ROUTE_URL.ticketCenter}`,
			position: 6,
			permission: PermissionKeyEnum.PORTAL_TICKET_CENTER
		}
	];
	return map;
};