import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Inject,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TdDataTableComponent, ITdDataTableSortChangeEvent } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, filter, from, lastValueFrom, map, mergeMap } from 'rxjs';
import { PermissionEnum } from '../../../model/enum/permission.enum';
import { MediaCenterContext, MediaCenterFilter, MediaCenterListModel, MediaCenterModel, MediaCenterResponseModel } from '../../../model/media-center.model';
import { MediaCenterService } from '../../../service/rest/media-center.service';
import { UtilDownloadService } from '../../../service/util-download.service';
import { UtilService } from '../../../service/util.service';
import { DialogDeleteComponent } from '../../../widget/dialog/dialog-delete/dialog-delete.component';
import { DialogNameEditComponent } from '../../../widget/dialog/dialog-name-edit/dialog-name-edit.component';
import { APP_CONFIG_TOKEN, DialogImageComponent, ISaepIctAngularCoreAppConfig, SubscribeManagerService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { homeColumnList } from '../../../constant/home-column-list.contstant';
import { AttachedFile } from '@saep-ict/pouch_agent_models';

@Component({
  selector: 'media-center-home',
  templateUrl: './media-center-home.component.html',
  styleUrls: ['./media-center-home.component.scss'],
  providers: [SubscribeManagerService]
})
export class MediaCenterHomeComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;

  columns = _.cloneDeep(homeColumnList);
  currentFolder = {} as MediaCenterModel;
  data: MediaCenterListModel = new MediaCenterListModel();
  permissionEnum = PermissionEnum;

  filter: MediaCenterFilter = {
    text: null,
    path: null
  };

  constructor(
    private dialog: MatDialog,
    private mediaCenterService: MediaCenterService,
    private translate: TranslateService,
    public utilService: UtilService,
    public utilDownloadService: UtilDownloadService,
    private subscribeManagerService: SubscribeManagerService,
    @Inject(APP_CONFIG_TOKEN) public appConfig: ISaepIctAngularCoreAppConfig,
    private matDialog: MatDialog
  ) {
    this.asyncInit();
    this.subscribeManagerService.populate(
      this.subscribeContextChange().subscribe(),
      'MediaCenterHomeComponent.subscribeContextChange'
    );
  }

  ngOnInit() {
    this.subscribeManagerService.populate(
      this.subscribeLanChange().subscribe(),
      'MediaCenterHomeComponent.subscribeLanChange'
    );
  }

  ngOnDestroy() {
    this.subscribeManagerService.destroy();
  }

  async asyncInit() {
    await this.tableColumnLangChange();
  }

  subscribeContextChange(): Observable<void> {
    try {
      return this.utilService.contextChange.pipe(
        filter(e => !!(e && e.filter && e.filter.path)),
        map(e => {
          this.filter.text = e.filter.text;
          this.filter.path = e.filter.path;
          this.filter.latest = e.filter.latest;
          this.filter = _.cloneDeep(this.filter);

          this.getFolderContent(e);
        }),
        catchError((error, caught) => {
          throw new Error(error);
        })
      );
    } catch(err) {
      throw new Error(err);
    }
  }

  subscribeLanChange(): Observable<void> {
    try {
      return this.translate.onLangChange.pipe(
        filter(e => !!(e)),
        mergeMap(e => from(this.tableColumnLangChange())),
        filter(e => !!(this.dataTable)),
        map(e => {
            this.dataTable.refresh();
        }),
        catchError((error, caught) => {
          throw new Error(error);
        })
      );
    } catch(err) {
      throw new Error(err);
    }
  }

  tableColumnLangChange(): Promise<void> {
    try {
      return new Promise(async resolve => {
        for (const col of this.columns) {
          if (col.label && col.trans_label_key) {
            col.label = await lastValueFrom(this.translate.get(col.trans_label_key))
          }
        }
        resolve();
      });
    } catch(err) {
      throw new Error(err);
    }
  }

  tableSortChange(e: ITdDataTableSortChangeEvent) {
    this.data.sort = e.name + e.order;
    const orderList = this.data.list.sort(function sortDataList(a, b) {
      return b[e.name] < a[e.name] ? 1 : b[e.name] > a[e.name] ? -1 : 0;
    });
    this.data.list = e.order === 'DESC' ? orderList : orderList.reverse();
    this.dataTable.refresh();
  }

  goToFolder(row: MediaCenterModel) {
    if (row.fileType === 'folder') {
      const context: MediaCenterContext = this.utilService.context.value;
      context.filter.path = row.path;
      this.utilService.context.next(context);
    }
  }

  goToItemFolder(row: MediaCenterModel) {
    const context: MediaCenterContext = this.utilService.context.value;
    context.filter.path = this.utilService.returnFileFolderContext(row).filter.path;
    delete context.filter.text;
    this.utilService.context.next(context);
  }

  filterTextChange() {
    if (this.filter.text !== null && this.filter.text !== undefined) {
      const searchText = this.filter.text.trim();
      const context: MediaCenterContext = this.utilService.context.value;
      if (searchText.length > 0) {
        context.filter.text = searchText;
      } else {
        delete context.filter.text;
      }
      context.filter.path = '/';
      this.utilService.context.next(context);
    }
  }

  breadcrumbPathChange(path: string) {
    const context: MediaCenterContext = this.utilService.context.value;
    context.filter.path = path;
    delete context.filter.text;
    this.utilService.context.next(context);
  }

  // dialog
  openDialogNameEdit(row: MediaCenterModel): void {
    const dialog = this.dialog.open(DialogNameEditComponent, {
      data: row.name,
      width: '80vw',
      panelClass: ['michelangelo-theme-dialog']
    });
    dialog.afterClosed().subscribe((name: string) => {
      if (name) {
        this.renameItem(row, name);
      }
    });
  }

  openDialogDelete(row: MediaCenterModel): void {
    const dialog = this.dialog.open(DialogDeleteComponent, {
      data: [name],
      width: '80vw',
      panelClass: ['michelangelo-theme-dialog']
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteItem(row.path);
      }
    });
  }

  // rest
  /**
   * Effettua le get verso gli endpoint:
   * - folder-content:
   *   - path: string
   *     -> restituisce il contenuto di una certa cartela identificata tramite path
   *   - path: "/Search/"
   *   - text: string
   *     -> restituisce una lista di file avente nome coerente con quanto ricercato
   *
   * Se non passato context.endpoint il metodo punta a 'folder-content'.
   * Se non passato context.path il metodo punta a quello storato presso utilService.context.
   *
   * @param  [context]
   * @memberof MediaCenterComponent
   */
  getFolderContent(context?: MediaCenterContext) {
    this.utilService.itemListSelected = [];
    this.mediaCenterService
      .getFolderContent(
        this.utilService.returnGetFolderQueryParam(context)
      )
      .then(async (res: MediaCenterResponseModel) => {
        this.currentFolder = res.data.currentFolder;
        const data_arr: MediaCenterModel[] = [];
        res.data.files = await this.returnBucketLinkThumbnail(res.data.files);
        data_arr.push(...res.data.folders, ...res.data.files);
        this.data.list = data_arr;
      })
      .catch((err) => {
        this.utilService.loadSnackbarWithAsyncTranslatedAction(
          'mc.error.get_folder_content',
          'mc.general.close',
          2000
        );
      });
  }

  deleteItem(path: string) {
    this.mediaCenterService
      .deleteFileList({pathList: [path]})
      .then((res: Response) => {
        const context: MediaCenterContext = this.utilService.context.value;
        context.filter.path = this.currentFolder.path;
        this.getFolderContent(context);
      })
      .catch((err) => {
        console.error(err);
        this.utilService.loadSnackbarWithAsyncTranslatedAction(
          'mc.error.delete_item',
          'mc.general.close',
          2000
        );
      });
  }

  renameItem(row: MediaCenterModel, newName: string) {
    this.mediaCenterService
      .renameItem(
        { path: this.currentFolder.path, oldName: row.name, newName: newName },
        null,
        { fileType: row.fileType === 'folder' ? row.fileType : 'file' }
      )
      .then((res: Response) => {
        const context: MediaCenterContext = this.utilService.context.value;
        context.filter.path = this.currentFolder.path;
        this.getFolderContent(context);
      })
      .catch((err) => {
        console.error(err);
        this.utilService.loadSnackbarWithAsyncTranslatedAction(
          'mc.error.rename_item',
          'mc.general.close',
          2000
        );
      });
  }

  openDialogPreview(e: AttachedFile) { 
    const dialogRef: MatDialogRef<DialogImageComponent> = this.matDialog.open(DialogImageComponent, {
			data: {
				title: e.name,
			  image: e.bucket_link_thumbnail
      },
      panelClass: ['michelangelo-theme-dialog']
		});
  }

  returnAttachmentFileNameThumbnail(e: string): string {
    try {
      const pos = e.lastIndexOf(".");
      return e.substring(0, pos < 0 ? e.length : pos) + ".jpg";
    } catch(err) {
      throw new Error(err);
    }
  }
  // Metodo per aggiungere all'array dei dati dei file link per la thumbnail
  async returnBucketLinkThumbnail(res: MediaCenterModel[]) : Promise<MediaCenterModel[]> {
    return new Promise(resolve => {
      try {
        const data_arr: MediaCenterModel[] = [];
        data_arr.push(...res);
        for (const dataElement of data_arr) {
            dataElement.bucket_link_thumbnail =  this.appConfig['envConfig'].bucketManager.be_url + '/' + this.utilService.context.value.filter.bucketFolderName + 
                  '/download-multipart?path=' + this.currentFolder.path + 'thumbnail/' + this.returnAttachmentFileNameThumbnail(dataElement.name) + '&token' + this.appConfig.token
        } 
        resolve(data_arr); 
      }  catch(err) {
          console.log(err);
          throw new Error(err);
      }
    });
  }
}
