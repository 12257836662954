import { OrderPdfData } from 'apps/spin8/src/app/widget/pdf-print/order-pdf/order-pdf.component';
import moment from 'moment';

export const display = {
	name: true,
	address: true,
	piva: true,
	phone: true,
	email: true,
	pec: true,
	order_type: true,
	order_progess: true,
	order_internal_number: true,
	order_creation_date: true,
	order_external_number: true,
	order_number: true,
	order_date: true,
	client_order_rif: true,
	client_date_rif: true,
	company_code: true,
	company_name: true,
	company_agent: true,
	currency:true,
	payment: true,
	delivery_warehouse: true,
	product_code: true,
	product_description: true,
	product_price: true,
	product_discount: true,
	product_ordered_quantity: true,
	product_qty_free: false,
	product_value: true,
	evasion_date: true,
	order_total: true
};

export const getPdfData = (order: any) => {
	const progress_map = [
		{ key: 'DRAFT', value: 'BOZZA' },
		{ key: 'READY_TO_SEND', value: 'INVIATO' },
		{ key: 'SENDING', value: 'INVIATO' },
		{ key: 'PROCESSING', value: 'IN LAVORAZIONE' },
		{ key: 'CONSOLIDATED', value: 'IN LAVORAZIONE' },
		{ key: 'PARTIALLY_FULFILLED', value: 'PARZIALMENTE EVASO' },
		{ key: 'FULFILLED', value: 'SPEDIATO' }
	];

	let pdfData = {} as OrderPdfData;
	let header = order && order.header;
	let organization = header && header.organization;
	pdfData.company = {
		name: organization && organization.business_name,
		code: organization && organization.code_erp,
	};
	pdfData.currency = header.currency && header.currency.description_short;
	pdfData.order_date = header && header.date && moment(header.date).format('DD/MM/yyyy');
	let order_causal_object = header && header.order_causal_object;
	pdfData.order_type = order_causal_object && order_causal_object.description;
	let order_progress_code = header && header.status;
	pdfData.order_progress = 'undefined';
	if (order_progress_code) {
		progress_map.forEach(cd => {
			if (order_progress_code == cd.key) pdfData.order_progress = cd.value;
		});
	}
	let csuite = order && order.csuite;
	pdfData.order_number = csuite && csuite.order_so_number + csuite.order_so_year + csuite.order_so_series;
	pdfData.order_external_number = csuite && csuite.order_ext_number;
	pdfData.order_internal_number = order && order._id && order._id.substring(order._id.length - 12).toUpperCase();
	pdfData.client_order_rif = order && order.customer_order_ref_code;
	pdfData.client_date_rif =
		header && header.customer_order_ref_date && moment(header.customer_order_ref_date).format('DD/MM/yyyy');
	pdfData.payment = header && header.payment_object && header.payment_object.description;
	let address = header && header.goods_destination_object;
	let zip_code = address && address.zip_code;
	pdfData.delivery_warehouse = [address && address.address];
	let locality = address && address.locality;
	let province = address && address.province;
	let full_locality = null;
	if (province) full_locality = zip_code + ' ' + locality + '(' + province.label + ')';
	else full_locality = zip_code + ' ' + locality;
	pdfData.delivery_warehouse.push(full_locality);
	let country = address.country;
	if (country) pdfData.delivery_warehouse.push(country);
	pdfData.evasion_date = header && moment(header.first_evasion_date).format('DD/MM/yyyy');
	pdfData.order_total = header && header.price && Math.round(+header.price.article * 100)/100;
	pdfData.products = [];	
	order.product_list.forEach(product => {
		let discount = 0;
		let discounts = product.discount;
		let value_base=(product.price * product.ordered_quantity );
		if (discounts) 
			if (discounts[0].type == 0) 
				discount = discounts[0].value;
					if(discount>0)
						discount =value_base*(discount/100)
		const value = +(value_base-discount).toFixed(2);
		const prod = {
			code: product.code_erp,
			description: product.description ? product.description : '-',
			discount: discount + ' %',
			ordered_quantity: product.ordered_quantity,
			price: product.price,
			qty_free: product.qty_free,
			value: value,
		};
		pdfData.products.push(prod);
	});
	return pdfData;
};
