import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { Injector } from '@angular/core';
import { GuidFormatterPipe } from '@saep-ict/angular-core';
import { TableOrderModel } from '../../../model/table/table-order.model';

const injector = Injector.create({
	providers: [
		{ provide: GuidFormatterPipe, deps: [] }
	]
});

const guidFormatterPipe = injector.get(GuidFormatterPipe);

export const addOrderId = (order: OrderStateModel): OrderStateModel => {
    if (order.csuite) {
        if (order.csuite.order_number && order.csuite.order_year) {
            order.header.order_id = `${order.csuite.order_year}_${order.csuite.order_number}`;
        }
    }
    return order;
}

export const getOrderVariationValue = (order: OrderStateModel, key: string) => {
    if (key && order && order.header && order.header.order_progress_detail) {
        if (order.header.order_progress_detail[key]) {
            return order.header.order_progress_detail[key];
        } else {
            return null;
        }
    }
}

export const getTableOrderList = (orderList: OrderStateModel[]): TableOrderModel[] => {
		let tableOrderList: TableOrderModel[] = [];

		tableOrderList = orderList
			.filter((order: OrderStateModel) => order.header)
			.map((order: OrderStateModel) => {
				const temp: TableOrderModel = order;
				temp.id_first_row = getOrderFirstRow(order, order.header.status);
				temp.id_second_row = getOrderSecondRow(order, order.header.status);
				return temp;
			});
		return tableOrderList;
	}

export const getOrderFirstRow = (order: OrderStateModel, status: OrderStatusEnum): string => {
    let firstRow: string;
    switch (status) {
        case OrderStatusEnum.CONSOLIDATED:
        case OrderStatusEnum.FULFILLED:
        case OrderStatusEnum.PARTIALLY_FULFILLED:
        case OrderStatusEnum.PROCESSING:
        case OrderStatusEnum.SENDING:
            if (order.csuite?.order_number && order.csuite?.order_year) {
                firstRow = `${order.csuite.order_number}_${order.csuite.order_year}`;
            }
            break;
    }
    return firstRow || guidFormatterPipe.transform(order._id);
}

export const getOrderSecondRow = (order: OrderStateModel, status: OrderStatusEnum): string => {
    let secondRow: string;
    switch (status) {
        case OrderStatusEnum.SENDING:
            secondRow = order.csuite?.order_ext_progress;
            break;
        case OrderStatusEnum.CONSOLIDATED:
        case OrderStatusEnum.FULFILLED:
        case OrderStatusEnum.PARTIALLY_FULFILLED:
            if (order.csuite?.order_so_number && order.csuite?.order_so_year) {
                secondRow = `${order.csuite.order_so_number}_${order.csuite.order_so_year}_${order.csuite.order_so_series}`;
            }
            break;
    }
    return secondRow;
}