import { HttpClient, HttpEventType } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Observable, Subject, Subscription, finalize, tap } from 'rxjs';
import { FileToUpload, Upload, upload } from './upload';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import { AttachmentListManager } from '../../model/widget/attachmment-list-manager.model';
import { RestAttachmentListManagerService } from '../rest/rest-attachment-list-manager.service';
import { APP_CONFIG_TOKEN, ISaepIctAngularCoreAppConfig } from '../../model/structure/lib-app-config.interface';
import { AngularCoreUtilService } from '../util/util.service';

@Injectable({ providedIn: 'root' })
export class UploadService implements OnDestroy {
	private progressSubscription: Subscription;
	private cachedObservable$: Subject<Upload>;
	private uploadCompleted$: Subject<AttachedFile>;
	private files: FileToUpload[] = [];
	private pathPrefix: string;

	constructor(
		private http: HttpClient,
		private restAttachmentListManagerService: RestAttachmentListManagerService,
		@Inject(APP_CONFIG_TOKEN) protected appConfig: ISaepIctAngularCoreAppConfig,
		private utilService: AngularCoreUtilService
	) {
		this.appConfig['config$'].subscribe(config => {
			if (config && config.bucketManager && config.bucketManager.be_url) {
				this.pathPrefix = config.bucketManager.be_url;
			}
		});
	}

	get hasFilesToUpload(): boolean {
		return this.files.length > 0;
	}

	get isUploadInProgress(): boolean {
		return this.files.length > 0 && this.cachedObservable$ != null;
	}

	get fileList(): FileToUpload[] {
		return this.files;
	}

	get uploadInProgressObservable(): Observable<Upload> {
		return this.cachedObservable$;
		//      ? this.cachedObservable$.asObservable()
		//      : EMPTY;
	}

	ngOnDestroy() {
		this.progressSubscription.unsubscribe();
	}

	addFileToUpload(file: File, configuration: AttachmentListManager.Configuration<AttachedFile>) {
		this.files.push({ file, configuration });
	}

	startUpload(): Observable<AttachedFile> {
		if (this.uploadCompleted$) {
			return this.uploadCompleted$;
		} else if (this.files.length > 0) {
			this.uploadCompleted$ = new Subject();
			return this.upload();
		} else {
			return EMPTY;
		}
	}

	async deleteAttachmentList(
		attachmentList: AttachedFile[],
		configuration: AttachmentListManager.Configuration<AttachedFile>
	): Promise<void> {
		try {
			const restDeletePayload: AttachmentListManager.RestDeletePayload = {
				pathList:
					attachmentList.map(i => {
						const fileName = i.nameOnBucket ? i.nameOnBucket : i.name;
						const pathQueryParamSegment = configuration.pathQueryParam ? `/${configuration.pathQueryParam}/` : '/';
						return pathQueryParamSegment + fileName;
					})
			};
			await this.restAttachmentListManagerService.delete(
				restDeletePayload,
				null,
				{ path: configuration.pathUrl }
			);
			return;
		} catch (err) {
			throw new Error(err);
		}
	}

	private upload(): Observable<AttachedFile> {
		if (!this.files?.length) {
			this.uploadCompleted$.complete();
			this.uploadCompleted$ = null;
			return EMPTY;
		}

		const fileToUpload = this.files[0];
		const formData = new FormData();
		let nameOnBucket: string;
		const timeStamp = Date.now();
		if (
			fileToUpload.configuration &&
			fileToUpload.configuration.upload &&
			Object.prototype.hasOwnProperty.call(fileToUpload.configuration.upload, 'fileNameOnBucketCreate')
		) {
			nameOnBucket = fileToUpload.configuration.upload.fileNameOnBucketCreate(fileToUpload.file.name, timeStamp);
		}
		if (nameOnBucket) {
			formData.set('file', fileToUpload.file, nameOnBucket);
		} else {
			formData.append('file', fileToUpload.file);
		}
		if (fileToUpload.configuration) {
			const pathQueryParamSegment =
				fileToUpload.configuration.pathQueryParam ? `/${fileToUpload.configuration.pathQueryParam}/` : '/';
			formData.append('path', pathQueryParamSegment);
		}

		this.cachedObservable$ = new Subject<Upload>();
		const obs = this.http
			.post(this.getPathPrefix() + `/${fileToUpload.configuration.pathUrl}/upload-multipart`, formData, {
				reportProgress: true,
				observe: 'events'
			})
			.pipe(
				tap(event => {
					if (event.type === HttpEventType.Response) {
						const attachedFile: AttachedFile = {
							id: null,
							alt: null,
							name: fileToUpload.file.name,
							nameOnBucket: nameOnBucket,
							data: null,
							bucket_link: null,
							date_creation: timeStamp
						};
						this.uploadCompleted$.next(this.utilService.deleteEmptyProperties(attachedFile));
					}
				}),
				upload(fileToUpload),
				finalize(() => {
					this.cachedObservable$.complete();
					this.cachedObservable$ = null;
					this.removeFileFromList(fileToUpload);
					this.upload();
				})
			);

		this.progressSubscription = obs.subscribe(this.cachedObservable$);
		return this.uploadCompleted$;
	}

	cancelUpload(index: number) {
		this.files.splice(index, 1);
		if (index == 0 && this.progressSubscription && !this.progressSubscription.closed) {
			this.progressSubscription.unsubscribe();
		}
	}

	private getPathPrefix() {
		return this.pathPrefix ? this.pathPrefix : '';
	}

	private removeFileFromList(fileToUpload: FileToUpload) {
		const fileIdx = this.files.indexOf(fileToUpload);
		if (fileIdx !== -1) {
			this.files.splice(fileIdx, 1);
		}
	}
}

